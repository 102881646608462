<template>
  <v-container>
    <div>
      <v-select
          v-model="category"
          v-on:change="change_categories"
          :items='$store.getters["categories_partitions/get_all_categories"]'
          label="Категории" persistent-hint
          clearable
          hint="Категория">
      </v-select>
      <v-text-field
          label="Название категории"
          v-model="name"
          :rules="[rules.counter]"
          maxlength="255"
      >
      </v-text-field>
      <!--      <v-textarea-->
      <!--          :hidden="false"-->
      <!--          label="Описание"-->
      <!--          v-model="description"-->
      <!--      >-->
      <!--      </v-textarea>-->
      <v-btn @click="update_category" :disabled="category === undefined">
        Сохранить изменения
      </v-btn>
      <v-btn @click="add_category" :disabled="category > 0" class="mx-2">
        Добавить новую категорию
      </v-btn>
      <v-btn @click="remove_category" :disabled="category === undefined">
        Удалить
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "categoriesEdit",
  methods: {
    change_categories(e) {
      if (this.category === 0) {
        this.category = undefined
      } else if (this.category === undefined) {
        this.category = undefined
      }
      this.name = this.$store.getters["categories_partitions/get_category"](this.category).text
    },
    add_category() {
      this.$store.dispatch("categories_partitions/category_action", {
        type: "add", data: {
          name: this.name,
          description: ""
        }
      }).then(result => {
        if (result.status_code === "2002") {
          //#TODO кушутель статус кодов глобальый
          this.$store.commit("snackbar/add_snackbar", {
            text: "Данная категория уже существует",
            type: "error",
            timeout: 4000
          })
        }
        if (result.status_code === "3000") {
          //#TODO кушутель статус кодов глобальый
          this.$store.commit("snackbar/add_snackbar", {
            text: "Категория успешная добавлена",
            type: "success",
            timeout: 4000
          })
        }
        this.$store.dispatch("categories_partitions/get_all").then(() => {
          this.category = undefined
          this.name = ""
        })
      })
    },
    update_category() {
      this.$store.dispatch("categories_partitions/category_action", {
        type: "update", data: {
          category_id: this.category,
          name: this.name,
          description: ""
        }
      }).then(result => {
        if (result.status_code === "3000") {
          //#TODO кушутель статус кодов глобальый
          this.$store.commit("snackbar/add_snackbar", {
            text: "Изменения успешно сохранены ",
            type: "success",
            timeout: 4000
          })
        }
        this.$store.dispatch("categories_partitions/get_all").then(() => {
          this.category = undefined
          this.name = ""
        })
      })
    },
    remove_category() {
      this.$store.dispatch("categories_partitions/category_action", {
        type: "remove", data: {
          category_id: this.category
        }
      }).then(result => {
        if (result.status_code === "3000") {
          //#TODO кушутель статус кодов глобальый
          this.$store.commit("snackbar/add_snackbar", {
            text: "Категория успешная удалена",
            type: "success",
            timeout: 4000
          })
        }
        this.$store.dispatch("categories_partitions/get_all").then(() => {
          this.category = undefined
          this.name = ""
        })
      })

    }
  },
  data: () => ({
    category: undefined,
    name: "",
    description: "",
    rules: {
      counter: value => value.length <= 255 || 'Max 255 characters',
    }
  })
}
</script>

<style scoped>

</style>