var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{staticClass:"d-flex flex-column mx-3"},[_c('v-divider',{attrs:{"inset":""}})],1),_c('h5',[_vm._v("Выбор тега")]),_c('v-text-field',{staticClass:"flex-row",attrs:{"label":"Найти тег","required":""},on:{"input":_vm.change_search_str},model:{value:(_vm.search_str),callback:function ($$v) {_vm.search_str=$$v},expression:"search_str"}}),_c('div',{staticClass:"text-center mt-2 mb-4"},[_c('v-progress-linear',{staticClass:"justify-center",attrs:{"indeterminate":"","active":_vm.timer_to_search !== undefined,"color":"primary","size":50}})],1),_c('div',[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('v-tab',[_vm._v("Поиск")]),_c('v-tab',[_vm._v("Выбранные ("+_vm._s(_vm.selected_tags_ids.length)+")")]),(_vm.add_new_tag)?_c('v-tab',[_vm._v("Добавить")]):_vm._e()],1)],1),_c('div',{ref:"div_tags",staticClass:"overflow-y-auto overflow-x-hidden"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 0),expression:"view === 0"}]},_vm._l((_vm.searched_tags),function(value,index){return _c('v-tooltip',{key:index,attrs:{"bottom":"","allow-overflow":"","max-width":"30vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mx-auto d-flex",on:{"click":function(){
                if(_vm.selected_tags_ids.includes(Number(value.id))){
                  _vm.sub_tag(value)
                } else {
                  _vm.add_tag(value)
                }
              }}},'v-card',attrs,false),on),[_c('div',[_c('v-card-title',[_vm._v(_vm._s(value.name))])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":_vm.selected_tags_ids.includes(Number(value.id)),"value":"value","text":""}},[_c('v-icon',[_vm._v(_vm._s((_vm.selected_tags_ids.includes(Number(value.id))) ? 'mdi-check-bold' : 'mdi-plus'))])],1)],1)]}}],null,true)},[_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s((value.description !== '') ? value.description : '(Описание отсутствует)'))])])}),1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 1),expression:"view === 1"}]},_vm._l((Object.values(_vm.selected_tags)),function(value,index){return (value.name.toLowerCase().includes(_vm.search_str.toLowerCase()))?_c('v-tooltip',{key:value.id,attrs:{"bottom":"","allow-overflow":"","max-width":"30vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mx-auto d-flex",on:{"click":function($event){return _vm.sub_tag(value)}}},'v-card',attrs,false),on),[_c('div',[_c('v-card-title',[_vm._v(_vm._s(value.name))])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3",attrs:{"value":"value","text":""}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)]}}],null,true)},[_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s((value.description !== '') ? value.description : '(Описание отсутствует)'))])]):_vm._e()}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 2),expression:"view === 2"}]},[_c('v-text-field',{attrs:{"label":"Название тега"},model:{value:(_vm.new_tag.name),callback:function ($$v) {_vm.$set(_vm.new_tag, "name", $$v)},expression:"new_tag.name"}}),_c('v-text-field',{attrs:{"label":"Описание тега"},model:{value:(_vm.new_tag.description),callback:function ($$v) {_vm.$set(_vm.new_tag, "description", $$v)},expression:"new_tag.description"}}),_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.create_new_tag}},[_vm._v("Добавить")]),_vm._v(" "+_vm._s(_vm.status_new_tag)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }