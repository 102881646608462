export function FileReadPart(reader, File_obj, start, size, callback) {
    const file_size = File_obj.size;
    let end = start + size
    if (end > file_size) {
        end = file_size
    }
    const sliceFile = File_obj.slice(start, end)
    return function () {
        return new Promise((resolve, reject) => {
                reader.onprogress = function (event) {
                    callback(file_size, start + event.loaded)

                }
                reader.onload = function () {
                    resolve(reader.result)
                }
                reader.readAsArrayBuffer(sliceFile)


            }
        )
    }
}

