<template>
  <v-app>
    <NavBar/>
    <v-card>

      <v-tabs>
        <v-tab v-if="show_buttons">
          <v-icon left>
            mdi-format-list-bulleted-square
          </v-icon>
          Категории
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-format-list-group
          </v-icon>
          Разделы
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-tag-multiple
          </v-icon>
          Теги
        </v-tab>
        <v-tab-item v-if="show_buttons">
          <v-card flat>
            <v-card-text>
              <categoriesEdit/>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <partitionEdit/>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <TagEdit></TagEdit>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-app>
</template>

<script>
import NavBar from '../components/NavBar'
import categoriesEdit from "../components/categoriesEdit";
import PartitionEdit from "../components/partitionEdit";
import categoriesAndPartitions from "../components/categoriesAndPartitions";
import TagEdit from "../components/TagEdit";


export default {
  name: "OtherToEdit",
  components: {PartitionEdit, NavBar, categoriesEdit, categoriesAndPartitions, TagEdit},
  methods: {},
  computed: {
    show_buttons() {
      console.log(this.$store.getters["current_user/current_user_level_access"] === "editor")
      return this.$store.getters["current_user/current_user_level_access"] === "admin";
    }
  },
}
</script>

<style scoped>

</style>