// const api_url = "https://dictionary.orionr.ru/api"
// const api_url = "http://localhost:3979"

if (window.location.hostname === "dictionary.orionr.ru") {
    const api_url = "https://dictionary.orionr.ru/api"
    module.exports = {
        api_url: api_url,
    };
    Object.defineProperty(
        module.exports,      // объект, где создаётся свойство
        "api_url", // свойство
        {
            value: api_url, // значение
            writable: false     // запретить изменять свойство
        }
    );
} else {
    const api_url = "http://localhost:3979"
    module.exports = {
        api_url: api_url,
    };
    Object.defineProperty(
        module.exports,      // объект, где создаётся свойство
        "api_url", // свойство
        {
            value: api_url, // значение
            writable: false     // запретить изменять свойство
        }
    );

}


