import {stex} from "@writewithocto/vue-ink/dist/stex";

export default {
    namespaced: true,
    state: {
        list_snackbar: {},
        count_snackbar: 0
    },
    getters: {
        get_list_snackbar(state) {
            return state.list_snackbar
        }
    },
    mutations: {
        add_snackbar(state, payload) {
            console.log("add snackbar")
            const id = Number(`${state.count_snackbar}`)
            state.list_snackbar[state.count_snackbar] = {
                text: payload.text,
                type: payload.type,
                close_snackbar : ()=>{
                    this.commit("snackbar/remove_snackbar", {id: id})
                }
            }
            setTimeout(() => {
                this.commit("snackbar/remove_snackbar", {id: id})
            }, payload.timeout)
            state.count_snackbar += 1
            return true
        },
        remove_snackbar(state, payload) {
            console.log("remove", payload, state.list_snackbar[payload.id])
            delete state.list_snackbar[payload.id]
            return true
        }
    },
    actions: {},
}
