<template>
  <section>
    <v-app>
      <v-row>
        <v-col></v-col>
        <v-col style="padding-top : 20%">
          <div class="text-h4">
            Авторизация
          </div>
          <v-form
              ref="form"
              v-on:submit="onSubmit"
          >
            <v-text-field
                v-model="email"
                label="E-mail"
                required
            ></v-text-field>
            <v-text-field
                v-model="password"
                label="Password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                counter
                @click:append="show = !show"
            ></v-text-field>
            <v-btn
                type="submit"
            >
              Login
            </v-btn>
          </v-form>
        </v-col>
        <v-col></v-col>
      </v-row>
      <GlobalSnackBar/>
    </v-app>

  </section>

</template>

<script>
import GlobalSnackBar from "../components/GlobalSnackBar";
import router from "../router"
import Dropdown from "../components/Dropdown";

export default {
  name: "SingIn",
  components: {
    GlobalSnackBar
  },
  data() {
    return {
      show: false,
      email: "",
      password: "",
      message: ""
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$store.dispatch("current_user/login", {
        email: this.email.trim(),
        password: this.password.trim()
      }).then(result => {
        console.log("login", result)
        if (result) {
          router.push({path: "/search_notes"})
        } else {
          this.message = "Неверный логин или пароль"
          this.$store.commit("snackbar/add_snackbar", {text: "Неверный пароль или логин", type: "error", timeout: 3000})

        }

      })
    },
  }
}
</script>

<style scoped>
</style>