<template>
  <v-container>
    <v-form class="mb-5 d-flex flex-column mx-3">
      <v-divider inset></v-divider>
      <v-text-field v-model="search_line" label="Найти пользователя" required
                    class="flex-row"></v-text-field>
      <v-btn @click="add_new_user" color="primary" outlined>
        Добавить пользователя
      </v-btn>
    </v-form>
    <div ref="div_tags" class="overflow-y-auto overflow-x-hidden">
      <div>
        <div hidden class="text-center mt-2 mb-4">
          <v-progress-circular
              hidden
              indeterminate
              color="primary"
              :size="50"
              class="justify-center"
          ></v-progress-circular>
        </div>
        <v-card :key="user.id" v-for="user in username_cache_list"
                class="mx-auto d-flex">
          <div>
            <v-card-title>{{ user.full_name }}</v-card-title>
            <v-card-subtitle>{{ user.email }}</v-card-subtitle>
          </div>
          <v-spacer></v-spacer>
          <v-btn outlined @click="edit_user(user.id)">
            <v-icon>mdi-account-arrow-right</v-icon>
          </v-btn>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
const rusLower = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя'
const rusUpper = rusLower.toUpperCase()
export default {

  name: "UserList",
  props: {
    value: Object
  },
  created() {
    let temp_username = []
    this.$store.dispatch("users/find_user", {type_search: "name", search_line: ""}).then(result => {
      for (const [key, value] of Object.entries(result)) {
        temp_username.push({
          full_name: value.full_name,
          email: value.email,
          id: key,
        })
      }
      this.username_cache_list = temp_username
    })
  },
  methods: {
    edit_user(id) {
      this.value['user_to_edit_now'] = Number(id)
      this.value['create_new_user'] = false
    },
    add_new_user() {
      this.value["user_to_edit_now"] = 0
      this.value["create_new_user"] = true
    }
  },

  watch: {
    search_line(search_str) {
      let temp_username = []
      let payload = {
        search_line: search_str
      }
      if (rusLower.includes(search_str[0]) || rusUpper.includes(search_str[0])) {
        payload["type_search"] = "name";
      } else {
        payload["type_search"] = "email";
      }

      this.$store.dispatch("users/find_user", payload).then(result => {
        for (const [key, value] of Object.entries(result)) {
          temp_username.push({
            full_name: value.full_name,
            email: value.email, id: key,
          })
        }
        this.username_cache_list = temp_username
      })

    }
  },
  data: () => ({
    username_cache_list: [],
    search_line: "",
    timer_to_search: 0
  })
}
</script>

<style scoped>

</style>