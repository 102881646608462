
<template>
  <v-app
      class="max_is_screen"
      v-bind:style="{
  }">
    <NavBar/>

    <v-navigation-drawer class="style_border" :disable-resize-watcher="true" :mobile-breakpoint="0"
                         v-model="$store.state.interface_app.extra_search" app
                         width="25%">
      <categoriesAndPartitions :start_category="$store.getters['search_params/get_all_params'].category_id"
                               :start_partition="$store.getters['search_params/get_all_params'].partition_id"
                               :callback="change_category_or_partition"/>
      <!--#TODO Remove callback style-->

      <TagFindForm :start_tags="$store.getters['search_params/get_all_params'].tags" :callback="change_tags"/>
    </v-navigation-drawer>
    <v-main>
      <SearchLine
          :start_value="$store.getters['search_params/get_all_params']"
          :callback="search_forms_update"/>
      <v-container ref="file_items" class="overflow-y-auto" :style="{width : '100vw'}">
        <div v-if='$store.getters["notes/list_notes"].length === 0'><h2>Ничего не найдено</h2></div>
        <v-list  v-if='$store.getters["notes/list_notes"].length > 0' two-line>
          <v-list-item-group
              multiple
          >
            <template v-for='(item, index) in $store.getters["notes/list_notes"]'>
              <v-list-item target="_blank" :href="`/note/${item.note_uuid}?can_i_back=true`" :key="index+item.note_uuid+item.name">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title class="text-lg-h5" v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                        v-text="item.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text :style="{fontSize : '10.5pt'}" class="text--primary"
                                             v-text="'Дата созд. ' + item.created_at.time.replace('T',' ')">
                    </v-list-item-action-text>
                    <v-list-item-action-text :style="{fontSize : '10.5pt'}" class="text--primary"
                                             v-text="'Дата обнв.   ' + item.updated_at.time.replace('T',' ')">
                    </v-list-item-action-text>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                  v-if="index < $store.getters['notes/list_notes'].length - 1"
                  :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "../components/NavBar.vue"
import TagFindForm from '../components/TagFindForm.vue';
import FilterForm from '../components/FilterForm.vue';
import NoteCard from "../components/NoteCard";
import categoriesAndPartitions from "../components/categoriesAndPartitions";
import SearchLine from "../components/SearchLine";
import global_const from "../global_const";
import object_hash from "object-hash"

export default {
  name: 'App',

  components: {
    NavBar, TagFindForm, FilterForm, NoteCard,
    categoriesAndPartitions, SearchLine
  },
  beforeCreate() {
    document.title = "Orion Cловарь"
    this.$store.dispatch("initialize_all_date", true)


  },
  beforeMount() {

    //this.load_search_params()

  },
  mounted() {
    //TODO государь а не хоти те ли вы сие говно исправить? (main)
    const max_value = window.innerHeight - 30 - this.$refs["file_items"].getBoundingClientRect().top
    this.$refs["file_items"].style.setProperty("max-height", max_value + "px")

  },
  created() {
    this.load_params()

  },
  methods: {
    load_params() {
      this.$store.commit("search_params/load_search_params", {
        query: this.$route?.query,
        hash: this.$route?.hash
      })
      this.$store.dispatch("search_params/send_search_request")
    },
    search_forms_update: function (payload) {

      this.$store.commit("search_params/change_sort_type", payload)
      this.$store.commit("search_params/set_look_for", payload)
      this.$store.commit("search_params/set_now_offset", payload)
      this.$store.commit("search_params/set_simple_search", payload)
      this.start_search()
    },
    change_category_or_partition: function (category, partition) {
      this.$store.commit("search_params/set_now_category", {category_id: category})
      this.$store.commit("search_params/set_now_partition", {partition_id: partition})
      this.start_search()
    },
    change_tags: function (value) {
      this.$store.commit("search_params/set_tags", {tags: value})
      this.start_search()
    },
    start_search: function () {
      //this.set_search_params()
      this.$store.dispatch("search_params/send_search_request")
    },

  },

  data: function () {
    return {drawer: false}
  },
};
</script>
<style>
.max_is_screen {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}
</style>