<template>
  <div class="col-xl-3 col-lg-3 col-sm-3 button_parant">
    <v-form>
      <v-text-field v-model="content" v-on:input="changeValue"
                    v-if="['name', 'description', 'file_name','file_ext', 'file_hash'].includes(type)" label="Поиск">
      </v-text-field>
      <v-tooltip v-if="['creator', 'owner', 'editor'].includes(type)" top>
        <template v-slot:activator="{ on, attrs }">
          <v-autocomplete
              v-bind="attrs"
              v-on="on"
              :items="username_cache_list"
              :search-input.sync="search"
              color="blue"
              v-model="content"
              v-on:input="changeValue"
              :no-data-text="'Пользователь не найден'"
              hide-selected
              label="Имя"
              placeholder="Ведите имя пользователя"
          ></v-autocomplete>
        </template>
        <span>{{ search }}</span>
      </v-tooltip>

      <v-menu v-if="['created_at', 'updated_at'].includes(type)"
              :close-on-content-click="false"
              ref="menu"
              transition="scale-transition"
              offset-y
              min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="content"
              label="Выберите период"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="date_temp"
            v-on:input="changeValue"
            locale="ru"
            range
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.menu.save(content)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-select v-on:input="changeValue" v-model="type" label="Параметр поиска" :items="list_selections"></v-select>

    </v-form>
    <v-btn
        v-on:click="close"
        class="button_position"
        color="danger"
        icon>
      <v-icon dark>
        mdi-window-close
      </v-icon>
    </v-btn>
  </div>

</template>

<script>
const rusLower = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя'
const rusUpper = rusLower.toUpperCase()

export default {
  name: 'FilterForm',
  props: {
    close_id: Number,
    value: Object,
    enter_value: Object,
    close_filter: Function
  },
  updated() {
//    console.log('FATHE RVALUE UPDATE')

  },
  created() {
    // const content = this.value.content
    // const type = this.value.type
    // if (["created_at", "updated_at"].includes(type)) {
    //   this.date_temp = this.content.split('~')
    //   this.content = content
    //   this.type = type
    // }

    if (['creator', 'owner', 'editor'].includes(this.value.type)) {

      this.$store.dispatch("users/find_user", {type_search: "email", search_line: this.value.content}).then(result => {
        const value = Object.entries(result)[0][1]
        this.username_cache_list = [{text: `${value.full_name} (${value.email})`, value: this.value.content}]
        this.search_str = undefined
      })
    }
    // if (['filename', 'file_short_description', 'sha1'].includes(type)) {
    //   this.type = type;
    //   this.content = content;
    // }
  },
  methods: {
    close() {
      //console.log(this.close_id)
      this.$emit("close_filter", this.close_id)
    },
    changeValue(e) {
      console.log(e)

      if (["created_at", "updated_at"].includes(this.type)) {
        if (this.date_temp.length === 1) {
          console.log("return")
          return
        }
        let one_date = Date.parse(this.date_temp[0]).valueOf()
        let two_date = Date.parse(this.date_temp[1]).valueOf()

        if (one_date > two_date) {
          const temp_var = this.date_temp[0]
          this.date_temp[0] = this.date_temp[1]
          this.date_temp[1] = temp_var
        }
        this.content = `${this.date_temp[0]}~${this.date_temp[1]}`
      }
      this.$emit('input', {content: this.content, type: this.type, count_id: this.value.count_id})
      this.$emit('change')
    }
  },
  watch: {
    search(search_str) {
      //console.log("search_str", search_str, this.content + ")")
      if (search_str === "" || search_str === this.content || !search_str || search_str.split("(")[1] === this.content + ")") {
        return
      }
      let temp_username = []
      let payload = {
        search_line: search_str
      }
      if (rusLower.includes(search_str[0]) || rusUpper.includes(search_str[0])) {
        payload["type_search"] = "name";
      } else {
        payload["type_search"] = "email";
      }
      this.$store.dispatch("users/find_user", payload).then(result => {
        //console.log("result serachh", result)
        for (const [key, value] of Object.entries(result)) {
          temp_username.push({
            text: `${value.full_name} (${value.email})`,
            value: value.email,
          })
        }
        this.username_cache_list = temp_username
      })

    }
  },
  data() {
    return {
      content: this.value.content,
      type: this.value.type,
      date_temp: [],
      username_cache_list: [],
      search: undefined,
      list_selections: [
        {text: "Название", value: "name"},
        {text: "Поиск в описании", value: "description"},
        {text: "Дата создания", value: "created_at"},
        {text: "Дата последнего обновления", value: "updated_at"},
        {text: "Создатель заметки", value: "creator"},
        {text: "Владелец заметки", value: "owner"},
        {text: "Редактировал заметку", value: "editor"},
        {text: "Название файла", value: "file_name"},
        {text: "Расширение файла", value: "file_ext"},
        {text: "Хеш файла", value: "file_hash"}
      ]
    }

  },
}
</script>

<style>
.button_parant {
  position: relative;
}

.button_position {
  position: absolute;
  right: -10px;
  top: 0;
}
</style>
