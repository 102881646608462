<template>
  <v-container class="d-inline-flex">
    <TakeOneTag v-model="now_tag"/>
    <v-card
        class="mx-4"
        elevation="0"
        max-width="40%"
    >
      <v-text-field v-model="name" label="Название тега"></v-text-field>
      <v-textarea v-model="description" label="Описание"></v-textarea>
      <div class="text-lg-left">
        <v-btn @click="add_tag" :disabled="now_tag !== 0" class="mx-2">
          Добавить новый тег
        </v-btn>
        <v-btn @click="update_tag" :disabled="now_tag === 0" class="mt-2 mx-2">
          Сохранить изменения
        </v-btn>
        <v-btn @click="remove_tag" :disabled="now_tag === 0" class="mx-2">
          Удалить тег
        </v-btn>
        <v-btn @click="clear" class="mx-2 mt-2">
          Очистить
        </v-btn>
        <v-btn @click="remove_not_used_tags" class="mx-2 mt-2" color="red">
          Удалить неиспользуемые теги
        </v-btn>
      </div>
    </v-card>
    <SnackBarMessage v-model="message"/>
  </v-container>
</template>

<script>
import TakeOneTag from '../components/TakeOneTag'
import SnackBarMessage from "./SnackBarMessage";

export default {
  components: {TakeOneTag, SnackBarMessage},
  name: "TagEdit",
  watch: {
    now_tag(new_value) {
      if (new_value !== 0) {
        this.request_tag_data()
      }

    }
  },
  methods: {
    request_tag_data() {
      this.$store.dispatch("tags/get_tags_by_list_ids", [Number(this.now_tag)]).then(result => {
        //console.log(result)
        const tag_info = result[String(this.now_tag)]
        this.name = tag_info.name
        this.description = tag_info.description
      })
    },
    remove_not_used_tags() {
      this.$store.dispatch("tags/remove_not_used_tags").then(result => {
        console.log(result)
        if (result.status_code === "3000") {
          //#TODO кушутель статус кодов глобальый
          this.$store.commit("snackbar/add_snackbar", {
            text: "Лишние теги удалены",
            type: "success",
            timeout: 4000
          })
        }
      })
    },
    add_tag() {
      this.$store.dispatch("tags/tags_action", {
        type: "add", data: {
          name: this.name,
          description: this.description
        }
      }).then(result => {
        if (result["status_code"] === "3000") {
          this.$store.commit("snackbar/add_snackbar", {
            text: "Тег добавлен",
            type: "success",
            timeout: 4000
          })
          this.clear()
        } else {
          this.$store.commit("snackbar/add_snackbar", {
            text: "Тег c таким название уже есть",
            type: "error",
            timeout: 4000
          })

        }
      })
    },
    remove_tag() {
      this.$store.dispatch("tags/tags_action", {
        type: "remove", data: {
          tag_id: this.now_tag
        }
      }).then(result => {
        if (result["result"] === true) {
          this.$store.commit("snackbar/add_snackbar", {
            text: "Тег удален",
            type: "success",
            timeout: 4000
          })

          this.clear()
        } else if (result["status_code"] === "2002") {
          this.$store.commit("snackbar/add_snackbar", {
            text: "Тег используется",
            type: "error",
            timeout: 4000
          })
        }
      })
    },
    update_tag() {
      this.$store.dispatch("tags/tags_action", {
        type: "update", data: {
          tag_id: this.now_tag,
          name: this.name,
          description: this.description
        }
      }).then(result => {
        if (result["result"] === true) {
          this.$store.commit("snackbar/add_snackbar", {
            text: "Изменения сохранены",
            type: "success",
            timeout: 4000
          })

          this.clear()
        }
      })
    },
    clear() {
      this.now_tag = 0
      this.name = ""
      this.description = ""
    }
  },
  data: () => ({
    now_tag: 0,
    name: "",
    description: "",
    message: "",
  })
}
</script>

<style scoped>

</style>