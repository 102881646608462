<template>
  <router-view/>
</template>

<script>

import GlobalSnackBar from "./components/GlobalSnackBar.vue"
import Dropdown from "./components/Dropdown";
//#TODO пересмотр запросов и сзадачи storage что выполняет а что не работает
export default {
  name: 'App',
  components: {
    GlobalSnackBar
  },
  created() {
    //Проверка сессии
    this.$store.dispatch("current_user/load_session")
    //Получение категорий и разделов
    this.$store.dispatch("categories_partitions/get_all")

  },
  data: () => ({
    //
  }),
};
</script>
