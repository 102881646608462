<template>

  <div>
    <v-text-field
        v-model="simple_search"
        label="Поиск"
        class="mx-3"

    ></v-text-field>
    <v-expansion-panels v-model="panel" accordion flat focusable :style="{paddingTop : 0}">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Дополнительные параметры
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex flex-row">
            <div class="flex-row d-flex overflow-y-hidden overflow-x-auto mr-2"
                 v-bind:style="{width : '100%', height : '160px'}">
              <v-btn
                  class="mx-5"
                  :style="{height : 'auto'}"
                  dark
                  color="indigo"
                  v-on:click="add_filter_form"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <FilterForm v-for="(item,i) in list_forms_ids"
                          v-model="filter_forms[item]"
                          v-bind:close_id="Number(item)"
                          v-on:close_filter="removeFilterForm"
                          v-on:change="changeContent" v-bind:key="item"/>

            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="d-flex ml-5 mt-5" v-bind:style="{paddingLeft : $vuetify.application.left}">
      <v-select v-model="sort_type" :items="search_type_items" label="Дата создания"
                :style="{maxWidth: '200px'}"
                class=""></v-select>
      <v-toolbar class="mt-3" elevation="0" dense>
        <div class="text-h10 justify-center">
        </div>
        <v-btn v-on:click="back_page" icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <input
            v-model="page"
            class="mt-0 pt-0"
            type="number"
            style="width: 60px"

            @input="page_change"
        >
        <div :style="{paddingLeft: '1rem'}"><span
            :style="{fontSize : '1.1rem'}">/ </span>{{ this.$store.getters['search_params/max_page'] }}
        </div>
        <v-btn v-on:click="next_page" icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-toolbar>
    </div>
  </div>

</template>

<script>
import FilterForm from '../components/FilterForm.vue';

export default {
  name: "SearchLine",
  props: {
    callback: Function,
    start_value: Object
  },
  created() {
    //console.log("start", this.start_value)
    if (this.start_value) {
      let list_form = {}
      if (this.start_value.simple_search) {
        this.simple_search = this.start_value.simple_search;
      }
      for (let i in this.start_value.look_for) {
        const content = this.start_value.look_for[i].content
        const type = this.start_value.look_for[i].type
        list_form[i] = {content: content, type: type}
        this.list_forms_ids.unshift(this.count_filters)
        this.count_filters += 1
      }
      this.filter_forms = list_form
      if (this.count_filters === 0) {
        this.add_filter_form()
      }

    }

  },

  data: () => ({
    panel: [],
    simple_search: "",
    temp_list_for: [],
    list_forms_ids: [],
    filter_forms: {},
    count_filters: 0,
    page: 1,
    sort_type: "new",
    search_type_items: [{text: "По убыванию", value: "new"}, {text: "По возрастанию", value: "old"}]
  }),
  computed: {
    new_result() {
      const offset = (Number(this.page) - 1) * 20
      return {
        look_for: this.temp_list_for,
        offset: offset,
        sort_type: this.sort_type,
        simple_search: this.simple_search
      }
    },
    max_pages() {
      return this.$store.getters["search_params/max_page"]
    }
  },
  watch: {
    panel: function (new_value, old_value) {
      this.$store.commit("interface_app/set_extra_search", !this.$store.state.interface_app.extra_search)
    },
    page: function (new_value, old_value) {

    },
    simple_search: function (new_value, old_value) {

    },
    new_result: function (new_value, old_value) {
      //console.log("callback", this.new_result)
      if (this.callback) {
        this.callback(this.new_result)
      }
    }
  },
  methods: {

    page_change() {
      if (Number(this.page) > this.max_pages) {
        this.page = this.$store.getters["search_params/max_page"]
      }
      if (Number(this.page) <= 0) {
        this.page = 1
      }
    },
    changeContent: function (id_change, value) {
      this.temp_list_for = Object.values(this.filter_forms).filter(value => value !== undefined)
    },
    add_filter_form: function () {
      this.filter_forms[this.count_filters] = new Object({
        content: "",
        type: "name",
        count_id: Number(this.count_filters)
      })
      this.list_forms_ids.unshift(this.count_filters)
      this.temp_list_for = Object.values(this.filter_forms)
      this.count_filters = this.count_filters + 1;
      //console.log("add filter form", this.filter_forms)
    },
    removeFilterForm: function (index) {
      delete this.filter_forms[index]
      const i = this.list_forms_ids.indexOf(index);
      if (i > -1) {
        this.list_forms_ids.splice(i, 1); // 2nd parameter means remove one item only
      }
      //this.list_forms_ids = Object.keys(this.filter_forms).filter(value => value !== undefined)
      this.changeContent()
    },
    back_page() {
      this.page = Number(this.page) - 1
      this.page_change()
    },

    next_page() {
      this.page = Number(this.page) + 1
      this.page_change()
    }
  },
  components: {
    FilterForm,
  }
}
</script>

<style scoped>

</style>