export default {
    namespaced: true,
    state: {
        categories_cache: [],
        partitions_cache: {},
    },
    getters: {
        get_all_categories(state) {
            return state.categories_cache
        },
        get_partitions: (state) => {
            return state.partitions_cache
        },
        get_category: (state) => (id) => {
            for (let [index, value] of Object.entries(state.categories_cache)) {
                if (value.value === id) {
                    return value
                }
            }
            return false
        },
        get_partition: (state) => (cat_id, id) => {
            for (let [index, value] of Object.entries(state.partitions_cache[cat_id])) {
                //console.log(value, index)
                if (value.value === id) {
                    return value
                }
            }
            return false
        }


    },

    mutations: {
        set_categories_and_partitions(state, payload) {
            state.categories_cache = payload["category"].map(item => {
                return {
                    text: item.name,
                    value: item.id,
                }
            })
            state.categories_cache.unshift({text: "Все категории", value: 0})
            for (let [key, value] of Object.entries(state.categories_cache)) {
                let items_list = payload["partition"].filter(partition => partition.category_id === value.value).map(item => {
                    return {
                        text: item.name,
                        value: item.id,

                    }
                })
                items_list.unshift({text: "Все разделы", value: 0})
                state.partitions_cache[value.value] = items_list
            }

        }
    },

    actions: {
        async get_all({commit, rootState}) {
            const response = await rootState.api.get("/category_and_partition/get_all")
            commit("set_categories_and_partitions", response["result"])
            return new Promise((resolve) => {
                resolve()
            })
        },

        async category_action({rootState, dispatch}, payload) {
            if (payload.type === "add") {
                return await rootState.api.put("/category/add", payload.data)
            } else if (payload.type === "remove") {
                return await rootState.api.delete("/category/remove", payload.data)
            } else if (payload.type === "update") {
                return await rootState.api.put("/category/update", payload.data)
            }
            dispatch('get_all')
            return false;

        },

        async partition_action({rootState, dispatch}, payload) {
            if (payload.type === "add") {
                return await rootState.api.put("/partition/add", payload.data)
            } else if (payload.type === "remove") {
                return await rootState.api.delete("/partition/remove", payload.data)
            } else if (payload.type === "update") {
                return await rootState.api.put("/partition/update", payload.data)
            }
            dispatch('get_all')
            return false;
        }

    },
}
