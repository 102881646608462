import store from "../store"

export default class RequestApi {
    constructor(server_url, access_token, refresh_token) {
        if (!access_token && !refresh_token) {
            if (localStorage["last_user"]) {
                access_token = JSON.parse(localStorage[localStorage.last_user]).access_token;
                refresh_token = JSON.parse(localStorage[localStorage.last_user]).refresh_token
            }
        }
        this.server_url = server_url;
        this.access_token = access_token;
        this.refresh_token = refresh_token;
    }

    async getToken(email, password) {
        // Авторизация
        let response = await fetch(this.server_url + "/user/login", {
            method: "PUT",
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })

        response = await response.json();
        if (response["status_code"] === "3000") {
            let result = {};
            result.access_token = response["result"]["access_token"];
            result.refresh_token = response["result"]["refresh_token"];
            result.exp_time = JSON.parse(atob(result.access_token.split('.')[1]))["exp"];
            result.level_access = response["result"]["level_access"];
            result.email = email;
            return result;
        } else {
            return false;
        }


    }

    async refreshTokens() {
        // Обновление токена
        console.log(this.access_token, this.refresh_token)
        let response = await fetch(this.server_url + "/user/refresh_access", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + this.refresh_token,
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                access_token: this.access_token,
            })
        })
        response = await response.json();
        //console.log(response)
        if (response["status_code"] === "3000") {
            let result = {};
            result.access_token = response["result"]["access_token"];
            result.refresh_token = response["result"]["refresh_token"];
            result.exp_time = JSON.parse(atob(result.refresh_token.split('.')[1]))["exp"];
            return result;
        } else {
            return false;
        }

    }

    async logout() {
        let response = await fetch(this.server_url + "/user/login", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + this.refresh_token,
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                access_token: this.access_token,
            })
        })
        response = await response.json();
        if (response["status_code"] === "3000") {
            this.refresh_token = "";
            this.access_token = "";
            return true;
        } else {
            return false;
        }

    }

    async refresh_tokens(result) {
        if (result["status_code"] === "0008") {
            return await store.dispatch("current_user/refresh_token")
        } else {
            return false
        }
    }

    async uploadFile() {

    }


//#TODO ошибка если не ответил сервер
    async get(path) {
        let response = await fetch(this.server_url + path, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + this.access_token,
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },

        })
        response = await response.json();
        if (await this.refresh_tokens(response)) {
            return await this.get(path)
        }
        return response;

    }

    async post(path, data) {
        let response = await fetch(this.server_url + path, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + this.access_token,
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })

        response = await response.json();
        if (await this.refresh_tokens(response)) {
            //console.log("repiat reqw")
            return await this.post(path, data)
        }
        return response;
    }

    async put(path, data) {
        let response = await fetch(this.server_url + path, {
            method: "PUT",
            headers: {
                "Authorization": "Bearer " + this.access_token,
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })

        response = await response.json();

        if (await this.refresh_tokens(response)) {
            return await this.put(path, data)
        }
        return response;

    }

    async delete(path, data) {
        let response = await fetch(this.server_url + path, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + this.access_token,
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })

        response = await response.json();
        if (await this.refresh_tokens(response)) {
            return await this.delete(path, data)
        }
        return response;

    }

    async uploadFormData(path, file_info, files_obj, callback) {
        let base64_to_sent = Buffer.from(JSON.stringify(file_info)).toString("base64")
        let formData = new FormData();
        formData.append("FileJson", base64_to_sent)

        for (let index in files_obj) {
            const file = files_obj[index]
            formData.append("files", file, file.name)
        }
        let xhrequest = new XMLHttpRequest();
        xhrequest.upload.onprogress = function (event) {
            if (callback) {
                callback(event.loaded, event.total)
            }
        }
        xhrequest.open("POST", this.server_url + path, true)
        xhrequest.setRequestHeader('Authorization', "Bearer " + this.access_token);
        xhrequest.send(formData)

        return new Promise(function (resolve, reject) {
            xhrequest.onload = function () {
                callback(0, 100)
                resolve(JSON.parse(xhrequest.response))
            }
        })

    }


}