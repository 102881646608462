import RequestApi from "../../func/RequestApi";
import global_const from "../../global_const";
import router from "../../router"

export default {
    namespaced: true,
    state: {
        email: "",
        level_access: "user",
        access_token: "",
        refresh_token: "",
        exp_time: 0
    },
    getters: {
        current_user_level_access(state) {
            return state.level_access
        }
    },

    mutations: {
        set_userdata(state, payload) {
            //console.log(payload)
            state.email = payload.email;
            state.exp_time = payload.exp_time;
            state.access_token = payload.access_token;
            state.refresh_token = payload.refresh_token;
            state.level_access = payload.level_access;
            let user_keys = JSON.stringify(
                {
                    access_token: payload.access_token,
                    refresh_token: payload.refresh_token,
                    exp_time: payload.exp_time,
                    level_access: payload.level_access
                }
            )
            localStorage[state.email] = user_keys;
            localStorage.last_user = state.email;
            //TODO Add Push pages

        },
    },

    actions: {

        login({commit, dispatch, rootState}, payload) {
            localStorage.clear();
            return new Promise((resolve) => {
                rootState.api.getToken(payload.email, payload.password).then(result => {
                    if (result["access_token"] && result["refresh_token"]) {
                        commit("set_userdata", result);
                        dispatch("update_api", true, {root: true}).then(result => {
                            resolve(true)
                        })
                        //commit("update_api", true, {root: true});
                    } else {
                        resolve(false)
                    }
                })
            })
        },
        load_session({commit, dispatch, rootState}, payload) {
            //Действия если мы уже авторизованны
            console.log("load_session")
            console.log(window.location.pathname)

            if (['login', 'set_password'].includes(window.location.pathname.split("/")[1])) {
                console.log("not return")
                return
            }

            let last_user = localStorage["last_user"];
            console.log("last user", last_user)

            console.log(last_user, (!last_user))
            if ((!last_user) || (!localStorage[last_user]) || (!Array.from(last_user).includes('@'))) {

                router.push({path: "/login"})
            }

            //console.log("1","2")
            let new_userdata = JSON.parse(localStorage[last_user])
            //console.log((Math.floor(Date.now() / 1000) - 420) > new_userdata.exp_time)
            //console.log(`${(Date.now() / 1000) - 420}`, new_userdata.exp_time)

            //Если наш аксесс токен истекает то мы обнавляем оба токена
            if (((Math.floor(Date.now() / 1000) - 420) > new_userdata.exp_time)) {
                rootState.api = new RequestApi(global_const.api_url)
                rootState.api.refreshTokens().then(result => {
                    //console.log(result)
                    if (!result) {
                        router.push("/login")
                    } else {
                        new_userdata.access_token = result.access_token;
                        new_userdata.refresh_token = result.refresh_token;
                        new_userdata.exp_time = result.exp_time;
                        new_userdata.email = last_user
                        rootState.api = new RequestApi(global_const.api_url,
                            new_userdata.access_token, new_userdata.refresh_token)
                        commit("set_userdata", new_userdata)
                    }
                })
            } else {
                new_userdata["email"] = last_user;
                console.log("new_userdata", new_userdata)
                commit("set_userdata", new_userdata)
                if (window.location.pathname === "/login") {
                    router.push("/search_notes")
                }
            }

            //dispatch("initialize_all_date", true, {root: true})
        },
        logout({rootState}) {
            console.log("logout")
            localStorage[localStorage.last_user] = ""
            localStorage.last_user = ""
            rootState.api = new RequestApi(global_const.api_url)
            localStorage.clear();
            router.push("/login")
        },
        async refresh_token({commit, dispatch, rootState}) {
            rootState.api = new RequestApi(global_const.api_url)
            let last_user = localStorage.last_user;
            let new_userdata = JSON.parse(localStorage[last_user])
            rootState.api.refreshTokens().then(result => {
                if (!result) {
                    router.push("/login")
                } else {
                    new_userdata.access_token = result.access_token;
                    new_userdata.refresh_token = result.refresh_token;
                    new_userdata.exp_time = result.exp_time;
                    rootState.api = new RequestApi(global_const.api_url,
                        new_userdata.access_token, new_userdata.refresh_token)
                    commit("set_userdata", new_userdata)
                    return true;
                }
            })
        },
        async activate_user({rootState}, payload) {
            return await rootState.api.put("/user/activate_user", payload)
        }
    },


}
  