import RequestApi from "../../func/RequestApi";
import global_const from "../../global_const";
import router from "../../router"

export default {
    namespaced: true,
    state: {
        extra_search: false,
    },
    getters: {},

    mutations: {
        set_extra_search(state, payload){
            state.extra_search = payload;
        }
    },

    actions: {},


}
