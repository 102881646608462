<template>
  <v-container>
    <v-text-field v-model="user_info.full_name" label="Полное имя пользователя">
    </v-text-field>
    <v-text-field v-model="user_info.email"
                  :rules="[rules.required, rules.email]"
                  label="E-mail">
    </v-text-field>
    <v-textarea v-model="user_info.additional_information" label="Доп.информация">
    </v-textarea>
    <div>
      <v-btn @click="add_new_user" class="mx-2">Создать пользователя</v-btn>
      <v-btn @click="$emit('update:addNewUser', false)" class="mx-2">Закрыть</v-btn>
    </div>
  </v-container>
</template>

<script>
const rusLower = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя'
const rusUpper = rusLower.toUpperCase()
export default {
  props: {
    addNewUser: Boolean,
    userId: Number,
  },
  computed: {},
  created() {
  },
  methods: {
    add_new_user() {
      let new_value = this.user_info
      let payload = {
        full_name: new_value.full_name,
        email: new_value.email,
        level_access: "user",
        additional_information: new_value.additional_information,
        list_category_id: []
      }
      this.$store.dispatch("users/create_user", payload).then(result => {
        if (result["status_code"] === "3000") {
          this.$emit('update:userId', result["result"].user_id)
          this.$emit('update:addNewUser', false)
          this.user_info = {
            full_name: new_value.full_name,
            email: new_value.email,
            level_access: "user",
            additional_information: new_value.additional_information,
            list_category_id: []
          }
          this.$store.commit("snackbar/add_snackbar", {text: "Пользователь создан", type: "success", timeout: 3000})
        }
        else {
          this.$store.commit("snackbar/add_snackbar", {text: "Произошла ошибка", type: "error", timeout: 3000})
        }
      })
    }
  },
  watch: {
    search(search_str) {
      if (search_str === null) {
        return
      }
      let temp_username = []
      let payload = {
        search_line: search_str
      }
      if (rusLower.includes(search_str[0]) || rusUpper.includes(search_str[0])) {
        payload["type_search"] = "name";
      } else {
        payload["type_search"] = "email";
      }

      this.$store.dispatch("users/find_user", payload).then(result => {
        for (const [key, value] of Object.entries(result)) {
         // console.log(key)
          temp_username.push({
            text: `${value.full_name} (${value.email})`,
            value: Number(key),
          })
        }
        this.username_cache_list = temp_username
      })

    }
  },
  data: () => ({
    user_info: {
      email: "",
      full_name: "",
      additional_information: "",
      level_access: "user",
      list_category_id: []
    },
    list_permission_user: [
      {text: "Пользователь", value: "user"},
      {text: "Редактор", value: "editor"},
      {text: "Администратор", value: "admin"}
    ],
    rules: {
      required: value => !!value || 'Обязательное поле.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Неверный e-mail.'
      },
    },
  }),
  name: "AddUser"
}
</script>


<style scoped>

</style>