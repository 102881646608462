<template>
  <v-container>
    <v-select
        :value="category"
        v-on:change="change_categories"
        :items='$store.getters["categories_partitions/get_all_categories"]'
        label="Категория" persistent-hint>
    </v-select>
    <v-select
        :value="partition"
        v-on:change="change_partitions"
        :disabled='has_partition'
        :items='$store.getters["categories_partitions/get_partitions"][category]' label="Раздел"
        persistent-hint>
    </v-select>
  </v-container>
</template>

<script>
//TODO del callbacks!
export default {
  name: "categoriesAndPartitions",
  beforeCreate: function () {
    this.$store.dispatch("categories_partitions/get_all")

  },
  created() {
    if (this.start_category) {
      this.category = this.start_category;
      if (this.category === 0) {
        this.has_partition = false
      }
    }
    if (this.start_partition) {
      this.partition = this.start_partition
    }

  },
  props: {
    start_category: Number,
    start_partition: Number,
    callback: Function
  },
  data: () => {
    return {
      category: 0,
      partition: 0,
    }
  },
  computed : {
    has_partition(){
      return this.$store.getters["categories_partitions/get_partitions"][this.category].length <= 1
    }
  },
  methods: {
    change_categories(value) {
      this.category = Number(value)
      this.partition = 0
      this.callback(this.category, this.partition)
    },
    change_partitions(value) {
      this.partition = Number(value)
      this.callback(this.category, this.partition)
    }
  }
}
</script>

<style scoped>

</style>