<template>
  <v-app
      class="max_is_screen"
      v-bind:style="{
  }">
    <NavBar/>
    <v-navigation-drawer class="overflow-hidden" :disable-resize-watcher="true" :mobile-breakpoint="0" app width="25%">
      <categoriesAndPartitions :start_category="old_note_data.start_category"
                               :start_partition="old_note_data.start_partition" :callback="callback_partition_id"/>
      <TagFindForm :add_new_tag="true" :start_tags="old_note_data.start_tags" :callback="callback_tags"/>
    </v-navigation-drawer>
    <v-main>
      <v-container class="overflow-y-auto" :style="{maxHeight : '91vh'}">
        <v-text-field
            v-model="note.name"
            counter="120"
            hint="Напишите название заметки."
            label="Название"
        ></v-text-field>
        <v-row class="mt-1">
          <h2 class="mx-3">Описание заметки</h2>
          <v-btn
              hidden
              class="mx-2"
              fab
              dark
              small
              color="primary"
          >
            <v-icon dark>
              mdi-minus
            </v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-col>
            <Ink class="light" :appearance="theme" :style="{caretColor:'black'}" v-model="note.description">
            </Ink>
          </v-col>
        </v-row>
        <v-row>
          <MultipleFileSelect :start_files="old_note_data.start_files" v-model="files"></MultipleFileSelect>
        </v-row>
        <v-row>
          <div class="pt-4">
            <ul style="list-style: none;">
              <li>
                <v-icon aria-hidden="false">
                  {{ (has_name) ? "mdi-check" : "mdi-close" }}
                </v-icon>
                Название заметки
              </li>
              <li>
                <v-icon aria-hidden="false">
                  {{ (has_description) ? "mdi-check" : "mdi-close" }}
                </v-icon>
                Описание заметки
              </li>
              <li>
                <v-icon aria-hidden="false">
                  {{ (has_tags) ? "mdi-check" : "mdi-close" }}
                </v-icon>
                Выбран хотя бы три тега
              </li>
              <li>
                <v-icon aria-hidden="false">
                  {{ (has_partition) ? "mdi-check" : "mdi-close" }}
                </v-icon>
                Выбран раздел
              </li>
              <li>
                <v-icon aria-hidden="false">
                  {{ (action_description) ? "mdi-check" : "mdi-close" }}
                </v-icon>
                Причина изменений
              </li>

            </ul>
          </div>
        </v-row>
        <v-row>
          <v-textarea
              class="mx-2"
              name="input-7-1"
              label="Причина изменений"
              v-model="note.description_action"
              hint="Напишите причину изменений"
          ></v-textarea>
        </v-row>
        <v-row>

          <v-btn
              :disabled="!done_hash || file_upload || !has_req_note || !action_description"
              :loading="file_upload"
              v-on:click="upload_file"
              class="mx-4 mt-3"
              color="primary"
              elevation="2"
          >Сохранить
          </v-btn>
        </v-row>
      </v-container>
    </v-main>
    <ProgressBar v-model="progress_loading" message="Загрузка данных! Не закрывайте страницу!"/>
  </v-app>
</template>

<script>
import NavBar from "../components/NavBar.vue"
import categoriesAndPartitions from "../components/categoriesAndPartitions";
import TagFindForm from '../components/TagFindForm.vue';
import Ink from '@writewithocto/vue-ink';
import MultipleFileSelect from "../components/MultipleFileSelect";
import ProgressBar from "../components/ProgressBar";

export default {
  name: 'FileEditor',
  props: ['note_uuid'],
  components: {
    ProgressBar,
    TagFindForm,
    Ink, NavBar, categoriesAndPartitions, MultipleFileSelect
  },
  beforeCreate() {

  },
  created() {
    //Иницилизация хеширователя

    //Получение данных заметки
    this.$store.dispatch("notes/get_info", this.note_uuid).then(result => {

      result["str_owner"] = ""
      this.note.note_uuid = this.note_uuid
      this.note.name = result.name
      this.note.description = result.description
      this.note.tags_list_ids = result.list_tags.map(item => item.id)
      this.note.partition_id = result.partition_id
      this.old_note_data = {
        start_tags: this.note.tags_list_ids,
        start_category: result.category_id,
        start_partition: result.partition_id,
        start_files: result.list_files
      }
      document.title = ` ${this.note.name} - Orion Словарь`
    }).catch(() => {
    })

  },
  computed: {
    show_error() {
      return this.error_message !== ""
    },
    has_name() {
      return this.note.name.trim() !== "" && this.note.name.trim().length < 120
    },
    has_description() {
      return this.note.description.trim() !== ""
    },
    has_tags() {
      return this.note.tags_list_ids.length > 2
    },
    has_partition() {
      return this.note.partition_id !== 0
    },
    has_req_note() {
      return this.has_name && this.has_description && this.has_tags && this.has_partition
    },
    action_description() {
      return this.note.description_action.trim() !== ""
    }

  },
  watch: {
    files: {
      handler(val) {
        let list_hash = this.files.map(item => item.hash)
        this.done_hash = !list_hash.includes("calc...");
      },
      deep: true
    }
  },
  methods: {
    callback_tags: function (tags) {
      this.note.tags_list_ids = tags;
    },
    callback_partition_id: function (category_id, partition_id) {
      this.note.partition_id = partition_id
    },
    load_progress: function (now, total) {
      this.progress_loading = Math.round((now / total) * 100)
    },
    upload_file() {
      if (this.file === null) {
        this.error_message = "Выберите файл."
        return
      }
      if (this.has_req_note) {

        this.file_upload = true
        let files_to_upload = {}
        let files_ids_to_add = {}
        console.log(this.files)
        let list_hash = this.files.map(item => item.hash)

        this.$store.dispatch("files/check_list_hash", {"list_files_hash": list_hash}).then(result => {
          console.log(result)
          for (let index in this.files) {
            const file = this.files[index]
            console.log(result.includes(file.hash), file.hash)
            if (!result.includes(file.hash)) {
              files_to_upload[file.hash] = file.file_obj
            }
          }

          this.note.list_files_hash = result

          this.$store.dispatch("notes/edit_note", {
            note: this.note,
            files: Object.values(files_to_upload),
            callback: this.load_progress
          }).then(result => {
            if (result["result"]) {
              this.$store.commit("snackbar/add_snackbar", {
                text: "Изменения применены",
                type: "success",
                timeout: 4000
              })
              this.$router.push({path: `/note/${result["result"].note_uuid}`})
            }
          })
        })

      }
    }
  },
  data: () => ({
    old_note_data: undefined,
    file_upload: false,
    theme: 'light',
    files: [],
    done_hash: true,
    progress_loading: 0,
    note: {
      partition_id: 0,
      name: "",
      description: '',
      tags_list_ids: [],
      list_files_hash: [],
      description_action: ""
    },

  }),
};
</script>

<style>
.bordered {
  border: solid #E0E0E0 2px;
  border-radius: 2%;
}

.light {
  background-color: #E0E0E0;
  padding: 0.5rem;
  border-radius: 0.3rem;
  --ink-code-background-color: rgba(0, 0, 0, 0.2);
}

.fit-content-height {
  height: fit-content;
}
</style>