<template>
  <div>
    <categoriesAndPartitions :callback="change_cat_and_part"/>
    <v-text-field
        label="Название раздела"
        v-model="name"
        :rules="[rules.counter]"
        maxlength="255"
    >
    </v-text-field>
    <!--    <v-textarea-->
    <!--        label="Описание"-->
    <!--        v-model="description"-->
    <!--    >-->
    <!--    </v-textarea>-->
    <v-btn @click="update_partition" :disabled="show_buttons">
      Сохранить изменения
    </v-btn>
    <v-btn @click="add_partition" :disabled="category <= 0 || category === undefined" class="mx-2">
      Добавить новый раздел
    </v-btn>
    <v-btn @click="remove_partition" :disabled="show_buttons">
      Удалить
    </v-btn>
  </div>
</template>

<script>
import categoriesAndPartitions from "./categoriesAndPartitions";

export default {
  name: "partitionEdit",
  components: {categoriesAndPartitions},
  computed: {
    show_buttons() {
      return !((this.category > 0) && (this.partition > 0))
    }

  },
  methods: {
    change_cat_and_part: function (category, partition) {
      this.category = category
      this.partition = partition
      if (this.partition > 0) {

        this.name = this.$store.getters["categories_partitions/get_partition"](this.category, this.partition)["text"]
        //console.log('name', this.$store.getters["categories_partitions/get_partition"](this.category, this.partition))
      } else {
        this.name = ""
      }
    },
    add_partition() {
      this.$store.dispatch("categories_partitions/partition_action", {
        type: "add", data: {
          category_id: this.category,
          name: this.name,
          description: this.description
        }
      }).then(result => {
        if (result.status_code === "3000") {
          //#TODO кушутель статус кодов глобальый
          this.$store.commit("snackbar/add_snackbar", {
            text: "Раздел успешно добавлен",
            type: "success",
            timeout: 4000
          })
        }
        this.name = ""
      })
    },
    update_partition() {
      this.$store.dispatch("categories_partitions/partition_action", {
        type: "update", data: {
          description: this.description,
          partition_id: this.partition,
          name: this.name,
        }
      }).then(result => {

        if (result.status_code === "3000") {
          //#TODO кушутель статус кодов глобальый
          this.$store.commit("snackbar/add_snackbar", {
            text: "Изменения успешно сохранены",
            type: "success",
            timeout: 4000
          })
        }


        this.$store.dispatch("categories_partitions/get_all").then(() => {
          this.category = undefined
          this.partition = undefined
          this.name = ""
        })
      })
    },
    remove_partition() {
      this.$store.dispatch("categories_partitions/partition_action", {
        type: "remove", data: {
          partition_id: this.partition
        }
      }).then(result => {
        if (result.status_code === "3000") {
          //#TODO кушутель статус кодов глобальый
          this.$store.commit("snackbar/add_snackbar", {
            text: "Раздел успешно удален",
            type: "success",
            timeout: 4000
          })
        }
        this.$store.dispatch("categories_partitions/get_all").then(() => {
          this.category = undefined
          this.partition = undefined
          this.name = ""
        })
      })

    }

  },
  data: () => ({
    category: undefined,
    partition: undefined,
    name: "",
    description: "",
    rules: {
      counter: value => value.length <= 255 || 'Max 255 characters',
    }
  })
}
</script>

<style scoped>

</style>