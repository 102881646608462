var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Передать файл")])]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"clearable":true,"items":_vm.username_cache_list,"search-input":_vm.search,"color":"blue","no-data-text":'Пользователь не найден',"hide-selected":"","label":"Имя","placeholder":"Ведите имя пользователя"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.changeValue},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function(){
              if(_vm.user_id !== 0){
                _vm.start_transfer = true
              }
            }}},[_vm._v(" Передать файл ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.start_transfer),callback:function ($$v) {_vm.start_transfer=$$v},expression:"start_transfer"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Вы уверены что хотите передать файл?")])]),_c('v-card-text',[_vm._v(" В случае передачи файла вы больше не сможете его редактировать. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function(){
              _vm.start_transfer = false
              _vm.user_id = 0
            }}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.transfer_file}},[_vm._v(" Уверен ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }