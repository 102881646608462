import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../pages/SignIn.vue'
import SearchFiles from '../pages/SearchFiles.vue'
import MainPage from "../pages/MainPage";
import NotePage from "../pages/NotePage";
import NoteAdd from "../pages/NoteAdd";
import NoteEdit from "../pages/NoteEdit";
import UsersPage from "../pages/UsersPage";
import OtherToEdit from "../pages/OtherToEdit"
import SetPassword from "../pages/SetPassword"

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'SignIn',
        component: SignIn
    },
    {
        path: '/',
        redirect: '/search_notes'
    },
    {
        path: '/das',
        name: 'SearchFiles',
        component: SearchFiles
    },
    {
        path: "/search_notes",
        name: "MainPage",
        component: MainPage
    },
    {
        path: "/add_note",
        name: "NoteAdd",
        component: NoteAdd
    },
    {
        path: "/edit_note/:note_uuid",
        name: "NoteEdit",
        component: NoteEdit,
        props: true
    },
    {
        path: "/users_page",
        name: "UsersPage",
        component: UsersPage,
    },
    {
        path: "/note/:note_id",
        name: "FilePage",
        component: NotePage,
        props: true
    },
    {
        path: "/set_password/:code",
        name: "SetPassword",
        component: SetPassword,
        props: true
    },
    {
        path: "/other",
        name: "OtherToEdit",
        component: OtherToEdit
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
