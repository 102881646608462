<template>
  <v-app>
    <v-main class="d-flex my-5 px-4">
      <v-row class="">

        <v-btn @click="back_page" text>{{ (this.can_i_back) ? "Закрыть" : "Главная страница" }}</v-btn>
        <v-col>
          <v-card
              class="mx-10 mt-8"
          >
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 ">{{ note.name }}</v-list-item-title>
                  <v-list-item-subtitle>Название заметки</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ note.owner.full_name }} ({{ note.owner.email }})</v-list-item-title>
                  <v-list-item-subtitle>Владелец заметки</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Текст заметки</v-list-item-title>
                  <v-list-item-subtitle
                      class="text--primary mt-4"
                  >
                    <vue_markdown>{{ note.description }}</vue_markdown>
                  </v-list-item-subtitle>

                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Теги заметки:</v-list-item-title>
                  <v-list-item-subtitle
                      class="text--primary mt-2"
                  >
                    <v-chip label class="ml-1" :key="tag.id" v-for="tag in note.list_tags">
                      {{ tag.name }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>
            <v-card-actions class="">
              <v-btn v-if="note.can_i_change" :href="`/edit_note/${this.note_id}`"
                     color="primary" text>Изменить
              </v-btn>
              <v-btn v-if="note.can_i_change" color="primary" @click="change_file_owner=true" text>Сменить
                владельца
              </v-btn>
              <v-btn v-if="note.can_i_change" @click="remove_file=true" color="red" text>Удалить</v-btn>
            </v-card-actions>
            <v-expansion-panels v-model="panel" accordion flat focusable :style="{paddingTop : 0}">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  История изменений.
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="mx-4 mb-4 overflow-auto">
                    <div v-for="[index, value] of Object.entries(note.history).slice().reverse()" :key="index">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ value.description.cause }}</v-list-item-title>
                          <v-list-item-subtitle>
                            Изменил: {{ value.full_name }} ({{ value.email }}) <br>
                            Дата внесения изменений: {{ value.action_at.replace("T", " ") }} <br>
                            Изменение файлов: {{ value.description.files ? "Да" : "Нет" }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>


          </v-card>
        </v-col>
        <v-col>
          <v-card
              v-if="show_files"
              class="mx-10 mt-8"
              :style="{maxWidth : '40vw'}"
          >
            <h4>Список файлов:</h4>
            <v-list >
              <v-list-item two-line v-for="(file, index) in note.list_files" v-bind:key="file.hash">
                <v-list-item-content>
                  <v-list-item-title>{{ file.filename }} ({{ file_size_str(file.file_size) }})</v-list-item-title>
                  <v-list-item-subtitle
                  >
                    Хеш файла: {{ file.hash }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn x-large icon>
                    <v-icon  @click="download_file(file.hash)" color="green lighten-0">mdi-download</v-icon>
                  </v-btn>
                </v-list-item-action>

              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

      </v-row>
    </v-main>
    <ChangeFileOwner :note_id="note_id" v-model="change_file_owner"></ChangeFileOwner>
    <RemoveFile :note_id="note_id" v-model="remove_file"></RemoveFile>
  </v-app>

</template>

<script>
import vue_markdown from "vue-markdown"
import ChangeFileOwner from "../components/ChangeFileOwner";
import RemoveFile from "../components/RemoveFile";

export default {
  name: 'FileInfo',
  components: {
    vue_markdown,
    ChangeFileOwner,
    RemoveFile
  },
  props: {
    note_id: String
  },
  created() {
    if (this.$route.query.can_i_back === "true") {
      this.can_i_back = true
      this.$router.push({query: {}})
      window.onpopstate = function (event) {
        window.close()
      };
    }
    this.$store.dispatch("notes/get_info", this.note_id).then(result => {
      console.log(result)
      result["str_owner"] = ""
      this.note = result
      console.log(this.note.history)
      document.title = ` ${this.note.name} - Orion Словарь`
    }).catch(() => {

    })
  },
  computed: {
    show_files() {
      if (this.note) {
        return Object.values(this.note.list_files).length > 0
      } else {
        return false
      }
    }
  },

  data: () => ({
    remove_file: false,
    note: {},
    change_file_owner: false,
    can_i_back: false,
    text_button: "Закрыть"
  }),
  methods: {
    back_button_close: function () {
      console.log("back!")
    },
    file_size_str(file_size) {
      let str_size = ""
      if (file_size > 1073741824) {
        str_size = `${(file_size / 1073741824).toFixed(2)} GB`
      } else if (file_size > 1048576) {
        str_size = `${(file_size / 1048576).toFixed(2)} MB`
      } else {
        str_size = `${(file_size / 1024).toFixed(2)} KB`
      }
      return str_size
    },
    back_page() {
      if (this.can_i_back) {
        window.close();
      } else {
        this.$router.push({path: '/search_notes'})
      }
    },
    download_file(file_hash) {
      this.$store.dispatch("files/download_file", file_hash).then()
    }
  },
};
</script>

<style scoped>
.height-10rem {
  height: 10rem;
}
</style>