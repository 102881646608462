<template>
  <v-app>
    <NavBar/>
    <v-navigation-drawer :disable-resize-watcher="true" :mobile-breakpoint="0" app width="30%">
      <UserList v-model="user_value"></UserList>
    </v-navigation-drawer>
    <v-main>
      <EditUser v-if="user_value.user_to_edit_now" :userId.sync="user_value.user_to_edit_now"/>
      <AddUser v-if="user_value.create_new_user" :addNewUser.sync="user_value.create_new_user" :userId.sync="user_value.user_to_edit_now"></AddUser>
    </v-main>

  </v-app>
</template>

<script>
import NavBar from '../components/NavBar'
import UserList from '../components/UserList'
import EditUser from '../components/EditUser'
import AddUser from '../components/AddUser'

export default {
  components: {
    NavBar, UserList, EditUser, AddUser
  },
  name: "UsersPage",
  watch: {
  },
  data: () => ({
    user_value: {
      user_to_edit_now: 0,
      create_new_user: false
    }
  })
}
</script>

<style scoped>

</style>