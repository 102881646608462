import router from "../../router"
import object_hash from "object-hash"

export default {
    namespaced: true,
    state: {
        category_id: 0,
        partition_id: 0,
        tags: [],
        look_for: [],
        limit: 20,
        offset: 0,
        sort_type: "new",
        count_items: 100,
        current_page: 0,
        simple_search: "",
        load: false,
    },
//TODO формы-компонеты делают простое управление и изменяются пересозданием массива
    getters: {
        get_all_params(state) {
            //console.log("tesgfad", state)
            return state
        },
        get_all_params_to_search(state) {
            let result_obj = {}
            for (const key in state.look_for) {
                result_obj[key] = state.look_for[key]
            }

            return result_obj
        },
        get_look_for(state){
          return state.look_for
        },
        get_all_tags(state) {
            return state.tags
        },
        max_page(state) {
            let max_page = Math.round(state.count_items / 20)
            if (max_page === 0) max_page += 1
            return max_page
        },
        type_search(state) {
            return state.sort_type
        },
        simple_search(state) {
            return state.simple_search
        }
    },
    mutations: {
        create_url_params(state, payload) {

        },
        set_count_filter(state, payload) {
            state.count_items = payload.count
        },
        change_sort_type(state, payload) {
            state.sort_type = payload.sort_type
            //переключить тип сортировки
        },
        set_tags(state, payload) {
            state.tags = payload.tags
        },
        set_now_offset(state, payload) {
            //Выбор текущей страницы
            state.offset = payload.offset;
        },
        set_now_category(state, payload) {
            state.category_id = payload.category_id
        },
        set_now_partition(state, payload) {
            state.partition_id = payload.partition_id
        },
        set_look_for(state, payload) {
            state.look_for = payload.look_for
        },
        set_simple_search(state, payload) {
            state.simple_search = payload.simple_search
        },
        set_search_params(state) {
            let query_obj = {
                category_id: 0,
                partition_id: 0,
                tags: [],
                content: [],
                type: [],
                simple_search: ""
            }

            query_obj.simple_search = state.simple_search;
            const now_request = state
            for (let index in now_request.look_for) {
                const filter = state.look_for[index]
                query_obj.content.push(filter.content)
                query_obj.type.push(filter.type)
            }

            query_obj.category_id = now_request.category_id
            query_obj.partition_id = now_request.partition_id
            query_obj.tags = now_request.tags

            let last_content = "fox"
            let last_type = "g"

            if (query_obj.content.length > 0) {
                last_content = query_obj.content[query_obj.content.length - 1].length
                if (!last_content) {
                    last_content = ""
                }
                last_type = query_obj.type[query_obj.type.length - 1][0]
            }

            let hash_value = object_hash.MD5(query_obj).slice(0, 6)

            router.replace({query: query_obj, hash: hash_value}).catch(err => {
            })
        },

        load_search_params(state, payload) {
            const now_query = payload.query
            const now_hash = payload.hash

            let look_for = []
            let restore_params = {
                category_id: 0,
                partition_id: 0,
                tags: [],
                content: [],
                type: [],
                simple_search: ""
            }
            restore_params.category_id = Number(now_query.category_id)
            restore_params.partition_id = Number(now_query.partition_id)
            if (now_query.tags) {
                if (typeof now_query.tags === "object") {
                    restore_params.tags = now_query.tags.map(value => Number(value))
                } else {
                    restore_params.tags = [Number(now_query.tags)]
                }
            }

            if (now_query.content !== undefined) {
                if (typeof now_query.content === "object") {
                    restore_params.content = now_query.content
                } else {
                    restore_params.content = [now_query.content]
                }
            }
            if (now_query.type !== undefined) {
                if (typeof now_query.type === "object") {
                    restore_params.type = now_query.type
                } else {
                    restore_params.type = [now_query.type]
                }
            }
            restore_params.simple_search = now_query.simple_search
            let last_content = "fox"
            let last_type = "g"
            if (restore_params.content.length > 0) {
                last_content = restore_params.content[restore_params.content.length - 1].length
                if (!last_content) {
                    last_content = ""
                }
                last_type = restore_params.type[restore_params.type.length - 1][0]
            }
            let hash_value = object_hash.MD5(restore_params).slice(0, 6)


            if ((("#" + hash_value) === now_hash) || now_hash === "#04e1bcd") {
                restore_params.look_for = []
                for (let [index] in restore_params.content) {
                    restore_params.look_for[index] = {
                        "content": restore_params.content[index],
                        "type": restore_params.type[index]
                    }
                }
                state.simple_search = restore_params.simple_search
                state.look_for = restore_params.look_for
                state.category_id = restore_params.category_id
                state.partition_id = restore_params.partition_id
                state.tags = restore_params.tags


            }
        }
    },
    actions: {
        send_search_request({commit, rootState, state, getters}, payload) {
            const values = getters["get_all_params"]
            commit("set_search_params")

            let temp_values = JSON.parse (JSON.stringify ( state))

            temp_values.look_for = temp_values.look_for.concat([{content: state.simple_search, type: "simple_search"}])
            rootState.api.post("/note/search", temp_values).then(result => {
                commit("set_count_filter", result["result"])
                commit("notes/set_notes_on_page", result["result"]["notes"], {root: true})
            })
            //Делает запрос с текущими параметрами
        },

    }

}
