export default {
    namespaced: true,
    state: {},
    getters: {},

    mutations: {},

    actions: {
        async find_user({rootState}, payload) {
            return new Promise((resolve) => {
                rootState.api.post("/user/find", payload).then(result => {
                    resolve(result["result"])
                })
            })
        },
        async get_user_info({rootState}, payload) {
            return await rootState.api.get(`/user/get/${payload}`)
        },
        async update_user({rootState}, payload) {
            return await rootState.api.post("/user/update_user", payload)
        },
        async create_user({rootState}, payload) {
            return await rootState.api.post("/user/create_user", payload)
        },
        async deactivate_user({rootState}, payload) {
            return await rootState.api.post("/user/deactivate_user", payload)
        },
        async hand_over_all_notes({rootState}, payload) {
            return await rootState.api.post("/user/user_hand_over_notes", payload)
        },
    },
}
