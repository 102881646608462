import xxhash from "xxhash-wasm";
import {FileReadPart, ReadAsArrayBuffer} from "../../func/utils_func"
import global_const from "../../global_const";

const CHUNK_SIZE = 1024 * 1024 * 128
const sleep = ms => new Promise(r => setTimeout(r, ms));
export default {
    namespaced: true,
    state: {
        files_on_page: {},
    },
    getters: {},

    mutations: {
        file_block(state) {
            state.file_block = true;
        },
        file_release(state) {
            state.file_block = false;
        }

    },

    actions: {
        //TODO сделать сразу возрат id если есть файл на сервере
        async calc_file_hash({rootState}, payload) {
            //TODO place to worker?
            payload.callback(100, 0)

            const {create64} = await xxhash();

            let FileToRead = payload.file_obj

            const count = Math.round(FileToRead.size / CHUNK_SIZE) + 1
            const now_harsher = create64()
            const reader = new FileReader()

            let start = 0
            let part_file = undefined
            let list_promise = []

            for (let i = 0; i < count; i++) {
                start = i * CHUNK_SIZE
                list_promise.push(FileReadPart(reader, FileToRead, start, CHUNK_SIZE, payload.callback))
            }
            for (let i in list_promise) {
                if (!payload.calc_hash) {
                    delete list_promise[i]
                    continue
                }
                part_file = await list_promise[i]()
                now_harsher.update(new Uint8Array(part_file))
            }
            if (!payload.calc_hash) {
                return
            }
            if (payload.callback) {
                payload.callback(count, count)
            }
            const hash_file = now_harsher.digest().toString(16)
            //console.log(hash_file)
            return {hash: hash_file}
        },

        async check_list_hash({commit, state, rootState}, payload) {
            let result = await rootState.api.post("/file/check_file_hash", payload)
            return result["result"]
        },
        async get_file_info({commit, state, rootState}, payload) {
            return await await rootState.api.get("/file/get/" + payload)["result"]
        },
        async download_file({rootState}, payload) {
            const result = await rootState.api.post("/file/get/token", {file_hash: payload})
            console.log(result)
            window.open(`${global_const.api_url}/file/get/bytes/${result["token"]}`, "_blank");
        },
    },
}
