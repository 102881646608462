<template>
  <v-card
      elevation="0"
      max-width="400px"
  >

    <v-text-field
        label="Название тега"
        v-model="search_line"
    />
    <v-toolbar elevation="0" dense>
      <v-btn v-on:click="page -=1" icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <input
          v-model="page"
          class="mt-0 pt-0"
          type="number"
          style="width: 60px"
      >
      <v-btn v-on:click="page += 1" icon>
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="set_list_tags">
      <v-tooltip :key="tag.id" v-for="tag in list_tags" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card
              max-width="400px"
              class="mt-2"
              v-bind="attrs"
              v-on="on"
              outlined
              elevation="2"
          >
            <v-card-actions>
              <v-card-title><h5>{{ tag.name }}</h5></v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon
                     v-on:click="$emit('input', tag.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <span>{{ (tag.description !== "") ? tag.description : "Нет описание у тега" }}</span>
      </v-tooltip>


    </div>
  </v-card>
</template>

<script>
export default {
  name: "TakeOneTag",
  created() {
    this.request_tags()
    this.value = this.now_tag
  },
  props: ["value"],
  data: () => ({
    search_line: "",
    list_tags: [],
    page: 1,
    max_page: 1,
    now_tag: 0,
  }),
  watch: {
    search_line(new_value) {
      this.request_tags()
    },
    page(new_value) {
      if (this.page < 1) {
        this.page = 1
      }
      if (this.page > this.max_page) {
        this.page = this.max_page
      }
      this.request_tags()
    },
    now_tag(new_value) {

    }
  },
  methods: {
    request_tags() {
      this.$store.dispatch("tags/find_tags_full", {
        name: this.search_line,
        limit: 20,
        offset: (this.page - 1) * 20
      }).then(result => {
        this.max_page = Math.ceil(result["result"]["count"] / 20)
        let compile_tags = []
        for (let [key, value] of Object.entries(result["result"]["tags"])) {
          compile_tags.push({name: value.name, id: key, description: value.description})
        }
        this.list_tags = compile_tags;
      })
    }
  }
}
</script>

<style scoped>
.set_list_tags {
  max-width: 400px;
  max-height: 500px;
  overflow-y: auto;
}

.text_set {
  text-overflow: ellipsis
}

</style>