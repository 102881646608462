<template>
  <v-snackbar
      v-model="show_snackbar"
      :timeout="3000"
  >
    {{ value }}
    <template v-slot:action="{ attrs }">
      <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="show_snackbar = false"
      >
        Закрыть
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBarMessage",
  props: ["value"],
  methods: {},

  watch: {
    show_snackbar: function (newvalue, oldvalue) {
      //console.log("show")
      if (this.show_snackbar === false) {
        this.$emit("input", "")
      }
    },
    value: function (newvalue, oldvalue) {
      //console.log(newvalue)
      this.show_snackbar = !!this.value.length;
    }
  },
  data: () => ({
    show_snackbar: false,
  })
}
</script>

<style scoped>

</style>