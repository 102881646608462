<template>
  <div>
    <v-dialog
        v-model="value"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Передать файл</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
              :clearable="true"
              :items="username_cache_list"
              :search-input.sync="search"
              color="blue"
              v-model="user_id"
              :no-data-text="'Пользователь не найден'"
              v-on:input="changeValue"
              hide-selected
              label="Имя"
              placeholder="Ведите имя пользователя"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="$emit('input', false)"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="function(){
                if(user_id !== 0){
                  start_transfer = true
                }
              }"
          >
            Передать файл
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="start_transfer"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Вы уверены что хотите передать файл?</span>
        </v-card-title>
        <v-card-text>
          В случае передачи файла вы больше не сможете его редактировать.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="function(){
                start_transfer = false
                user_id = 0
              }"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              v-on:click="transfer_file"
          >
            Уверен
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
const rusLower = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя'
const rusUpper = rusLower.toUpperCase()
export default {
  name: "ChangeFileOwner",
  props: {
    note_id: String,
    value: Boolean,
  },
  computed: {},
  data: () => ({
    username_cache_list: [],
    search: undefined,
    user_id: 0,
    start_transfer: false
  }),
  methods: {
    changeValue(e) {
      //console.log(this.user)
    },
    transfer_file() {
      this.$store.dispatch("notes/change_file_owner", {
        description_action: "Смена владельца файла",
        note_uuid: this.note_id,
        new_owner: Number(this.user_id)
      }).then(result => {
            console.log(result)
            if (result["status_code"] === "3000") {
              location.reload();
            } else {
              //console.log(result)
            }
          }
      )

    }
  },
  watch: {
    search(search_str) {
      let temp_username = []
      let payload = {
        search_line: search_str
      }
      if (rusLower.includes(search_str[0]) || rusUpper.includes(search_str[0])) {
        payload["type_search"] = "name";
      } else {
        payload["type_search"] = "email";
      }
      //console.log(payload)
      this.$store.dispatch("users/find_user", payload).then(result => {
        //console.log("result serachh", result)
        for (const [key, value] of Object.entries(result)) {
          temp_username.push({
            text: `${value.full_name} (${value.email})`,
            value: key
          })
        }
        this.username_cache_list = temp_username
      })

    }
  }


}
</script>

<style scoped>

</style>