export default {
    namespaced: true,
    state: {
        //https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB
        //TODO проверить на скорость
        cache_tags: {},
        searched_tags: {},
        find_string: ""

    },
    getters: {
        get_tags(state) {
            return state.cache_tags
        }
    },

    mutations: {
        set_text_to_search_tags(state, payload) {
            state.find_string = payload
        },
        add_tag_to_cache(state, payload) {
            state.cache_tags[payload.name] = payload.id;
        },
        remove_tag_from_cache(state, payload) {
            delete state.cache_tags[payload.name]
        }
    },

    actions: {
        //TODO fix duplicate
        async find_tags_full({commit, rootState, store}, payload) {
            return await rootState.api.post("/tag/search", payload)
        },
        find_tags({commit, rootState, state}, payload) {
            let offset = 0
            if (payload["offset"]) {
                offset = payload["offset"]
            }
            return new Promise((resolve) => {
                rootState.api.post("/tag/search",
                    {
                        name: payload.name,
                        offset: offset,
                        limit: 100
                    }
                ).then(result => {
                    let compile_tags = []
                    for (let [key, value] of Object.entries(result["result"]["tags"])) {
                        compile_tags.push({name: value.name, id: key, description: value.description})
                    }
                    resolve(compile_tags)
                })

            })
        },
        get_tags_by_list_ids({rootState, dispatch}, payload) {
            return new Promise((resolve) => {
                rootState.api.post("/tag/gets",
                    {
                        list_tags: payload
                    }
                ).then(result => {
                    resolve(result["result"])
                })
            })
        },
        async tags_action({rootState, dispatch}, payload) {
            if (payload.type === "add") {
                return await rootState.api.put("/tag/add", payload.data)
            } else if (payload.type === "remove") {
                return await rootState.api.post("/tag/remove", payload.data)
            } else if (payload.type === "update") {
                return await rootState.api.post("/tag/update", payload.data)
            } else {
                return false
            }
        },
        async remove_not_used_tags({rootState}){
            return await rootState.api.post("/tag/remove_not_used_tags", {})

        }
    },
}
