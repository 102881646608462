<template>
  <div>
    <div>
      <v-snackbar
          v-for="(snackbar, index) in list_snackbar"
          app
          v-model="snackbar.text.length > 0"

          :color="snackbar.type"
          elevation="24"
          :style="`bottom: ${index * 60}px`"
          :key="snackbar.id"
      >

        {{ snackbar.text }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar.close_snackbar"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalSnackBar",
  data() {
    return {
      list_snackbar: []
    }
  },

  created() {
    this.subscriber = this.$store.subscribe((mutation, state) => {
      if ((mutation.type === "snackbar/remove_snackbar") || (mutation.type === "snackbar/add_snackbar")) {
        this.list_snackbar = Object.values(state.snackbar.list_snackbar).map(result => {
          console.log(result)
          return {type: result.type, text: result.text, close_snackbar: result.close_snackbar}
        })

      }
      // called after every mutation.
      // The mutation comes in the format of `{ type, payload }`.
    })
  },
  beforeDestroy() {
    this.subscriber()
  }


}
</script>

<style >
.error {

}

</style>