//viewFile
import global_const from '../../../global_const'

export default {
    namespaced: true,
    state: {},
    getters: {
        get_file_from_cache_storage: (state) => (id) => {
            //Получения файла из кеша поиска
        }
    },
    mutations: {},

    actions: {

    },
}
