<template>
  <div class="text-center">
    <v-dialog
        v-model="show_progress"
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          {{message}}
          <v-progress-linear
              v-model="value"
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: ["value", "message"],
  data() {
    return {
      show_progress: false
    }
  },
  watch: {
    value() {
      if (this.value > 0) {
        this.show_progress = true
      }

    }
  }
}
</script>

<style scoped>

</style>