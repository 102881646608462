<template>
  <tbody class="px-2">
  <tr class="">
    <td v-on:click="open_file_link">
      <div :style="{maxWidth : '350px', margin : '10px', wordWrap : 'break-word', fontWeight: 'bold'}">{{
          name
        }}
      </div>
    </td>
    <td v-on:click="open_file_link">
      <div class="truncate-text"><vue_markdown>{{ description }}</vue_markdown></div>
    </td>
    <td v-on:click="open_file_link">
      <div :style="{width : '150px'}">
        Создан:<br/>{{ creation_date.replace("T", " ") }}
        Обновлен:<br/>{{ updated_data.replace("T", " ") }}
      </div>
    </td>
    <td>
      <div :style="{fontSize : '13.8px', position : 'relative',  display : 'block', float : 'right'}">
        <v-btn
            text
            color="blue accent-4"
            @click="copy_link"
        >
          Скопировать сыллку
        </v-btn>

      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
import vue_markdown from "vue-markdown"
import router from '../router'

export default {
  components: {vue_markdown},
  name: "NoteCard",
  props: {
    id: Number,
    name: String,
    description: String,
    creation_date: String,
    updated_data: String,
  },
  data: () => {
    return {reveal: false}
  },
  methods: {
    copy_link() {
      navigator.clipboard.writeText(window.location.host + `/note/${this.id}`)
    },
    open_file_link() {
      window.open(`/note/${this.id}?can_i_back=true`, "_blank")
    }
  }
}
</script>

<style scoped>


.brd {
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
}


/*.v-card--reveal {*/
/*  bottom: 0;*/
/*  opacity: 1 !important;*/
/*  position: absolute;*/
/*  width: 100%;*/
/*}*/
.truncate-text {
  word-wrap: break-word;
  max-height: 100px;
  padding: 1rem;
  overflow: hidden;
  position: relative;
}

.truncate-text:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, transparent, #fffff 50%);
}
</style>