<template>
  <v-container>
    <v-form class="d-flex flex-column mx-3">
      <v-divider inset></v-divider>
    </v-form>
    <h5>Выбор тега</h5>
    <v-text-field v-on:input="change_search_str" v-model="search_str" label="Найти тег" required
                  class="flex-row"></v-text-field>
    <div class="text-center mt-2 mb-4">
      <v-progress-linear
          indeterminate
          :active="timer_to_search !== undefined"
          color="primary"
          :size="50"
          class="justify-center"
      ></v-progress-linear>
    </div>
    <div>
      <v-tabs
          grow
          v-model="view"
      >
        <v-tab>Поиск</v-tab>
        <v-tab>Выбранные ({{ selected_tags_ids.length }})</v-tab>
        <v-tab v-if="add_new_tag">Добавить</v-tab>
      </v-tabs>
    </div>
    <div ref="div_tags" class="overflow-y-auto overflow-x-hidden">
      <div v-show="view === 0">
        <v-tooltip
            v-for="(value, index) in searched_tags"
            v-bind:key="index"
            bottom allow-overflow max-width="30vw">
          <template v-slot:activator="{ on, attrs }">
            <v-card
                v-bind="attrs"
                v-on="on"
                class="mx-auto d-flex"
                v-on:click="function(){
                  if(selected_tags_ids.includes(Number(value.id))){
                    sub_tag(value)
                  } else {
                    add_tag(value)
                  }
                }"
            >
              <div>
                <v-card-title>{{ value.name }}</v-card-title>
              </div>
              <v-spacer></v-spacer>
              <v-btn :disabled="selected_tags_ids.includes(Number(value.id))" class="mt-3"
                     value="value" text>
                <v-icon>{{ (selected_tags_ids.includes(Number(value.id))) ? 'mdi-check-bold' : 'mdi-plus' }}</v-icon>
              </v-btn>
            </v-card>
          </template>
          <span class="ml-1">{{ (value.description !== '') ? value.description : '(Описание отсутствует)' }}</span>
        </v-tooltip>
      </div>
    </div>
    <div v-show="view === 1">
      <v-tooltip
          v-for="(value, index) in Object.values(selected_tags)"
          v-if="value.name.toLowerCase().includes(search_str.toLowerCase())"
          v-bind:key="value.id"
          bottom allow-overflow max-width="30vw">
        <template v-slot:activator="{ on, attrs }">
          <v-card
              v-bind="attrs"
              v-on="on"
              class="mx-auto d-flex"
              v-on:click="sub_tag(value)">
            <div>
              <v-card-title>{{ value.name }}</v-card-title>
            </div>
            <v-spacer></v-spacer>
            <v-btn class="mt-3" value="value" text>
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-card>
        </template>
        <span class="ml-1">{{ (value.description !== '') ? value.description : '(Описание отсутствует)' }}</span>
      </v-tooltip>
    </div>
    <div v-show="view === 2">
      <!--TODO переделдать на форму-->
      <v-text-field
          v-model="new_tag.name"
          label="Название тега"
      ></v-text-field>
      <v-text-field
          v-model="new_tag.description"
          label="Описание тега"
      ></v-text-field>
      <!--TODO валидация кнопки-->
      <v-btn @click="create_new_tag" color="green">Добавить</v-btn>
      {{ status_new_tag }}
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'TagFindForm',
  props: {
    callback: Function,
    start_tags: Array,
    add_new_tag: Boolean
  },
  mounted() {
    //TODO государь а не хоти те ли вы сие говно исправить? (tags_list)


  },
  updated() {

    const max_value = window.innerHeight - 15 - this.$refs["div_tags"].getBoundingClientRect().top
    console.log(max_value)
    this.$refs["div_tags"].style.setProperty("max-height", max_value + "px")
  },
  created() {
    if (this.start_tags) {
      this.$store.dispatch("tags/get_tags_by_list_ids", this.start_tags).then(result => {
        //console.log("START_TAGS", result)
        this.selected_tags_ids = Object.keys(result).map(item => {
          return Number(item)
        })
        for (let [key, value] of Object.entries(result)) {
          this.selected_tags[key] = value
          this.selected_tags[key].id = key
        }
      })
    }
    this.find_tags_request()
  },
  methods: {
    //TODO исправление на вечную прокрутку?
    add_tag: function (tag_value) {
      this.selected_tags[tag_value.id] = tag_value
      this.selected_tags_ids = Object.keys(this.selected_tags).map(item => Number(item))
      this.callback(this.selected_tags_ids)

    },
    sub_tag: function (tag_value) {
      delete this.selected_tags[tag_value.id]
      this.selected_tags_ids = Object.keys(this.selected_tags).map(item => Number(item))
      this.callback(this.selected_tags_ids)
    },

    find_tags_request: function () {
      this.$store.dispatch("tags/find_tags", {name: this.search_str}).then(result => {
        let start = []
        let end = []
        for (let tag in result) {
          const value = result[tag];
          if (this.selected_tags_ids.includes(Number(value.id))) {
            start.push(value)
          } else {
            end.push(value)
          }
        }
        this.searched_tags = start.concat(end);
      })
      this.timer_to_search = undefined
    },
    change_search_str: function (value) {
      clearTimeout(this.timer_to_search)
      this.timer_to_search = setTimeout(this.find_tags_request, 250);
    },

    create_new_tag() {
      this.$store.dispatch("tags/tags_action", {
        type: "add", data: this.new_tag
      }).then(result => {
        if (result["status_code"] === "3000") {
          this.$store.commit("snackbar/add_snackbar", {text : "Тег успешно создан", type: "success", timeout: 3000})


          const new_tag = result["result"]
          this.new_tag = {name: "", description: ""}

          this.selected_tags[new_tag.id] = new_tag
          this.selected_tags_ids = Object.keys(this.selected_tags).map(item => Number(item))

          this.callback(this.selected_tags_ids)
          //TODO единая система сообщений
          setTimeout(() => {
            this.status_new_tag = ""
          }, 2000)

        } else if (result["status_code"] === "2002") {
          this.$store.commit("snackbar/add_snackbar", {text : "Тег с таким названием уже существует!", type: "error", timeout: 3000})

        } else {
          this.$store.commit("snackbar/add_snackbar", {text : "Неудалось создать новый тег.", type: "success", timeout: 3000})

        }
      })
    }
  },
  computed: {},
  data: () => ({
    new_tag: {
      name: "",
      description: ""
    },
    status_new_tag: "",
    timer_to_search: undefined,
    searched_tags: [],
    selected_tags: {},
    selected_tags_ids: [],
    search_str: "",
    view: 0,

  })
}
</script>

<style scoped>
.fill_all {
  width: 100%;
}
</style>