<template>
  <v-card

      max-width="1024px"
      class="ml-2"
  >

    <v-list
        subheader
        two-line
    >
      <v-subheader inset>Файлы</v-subheader>

      <v-list-item
          v-for="(item, index) in files"
          :key="item.file_obj.name"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.file_obj.name }} ({{ file_size_str(item.file_obj.size) }})</v-list-item-title>
          <v-list-item-subtitle>Хеш файла: {{ item.hash }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="d-inline">
          <v-btn icon class="">
            <v-progress-circular class="mx-2" color="green" :value="item.progress"></v-progress-circular>
          </v-btn>

          <v-btn @click="remove_file(index)" icon>
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-btn class="ma-3" @click="add_file">добавить файлы</v-btn>
  </v-card>
</template>

<script>
export default {
  name: "MultipleFileSelect",
  props: ["value", "start_files"],

  created() {
    this.$store.commit("files/file_release")
    this.$store.dispatch("files/init_worker")
    if (this.start_files) {
      console.log(this.start_files)
      for (let key in this.start_files) {

        const data_file = this.start_files[key]

        let file_obj = new File([""], data_file.filename);
        Object.defineProperty(file_obj, 'size', {value: data_file.file_size, configurable: true})

        this.files.push({
          file_obj: file_obj,
          hash: data_file.hash,
          progress: 100,
          calc_hash: true,
        })
      }
    }
  },
  data: () => ({
    files: []

  }),
  //TODO НЕ УДАЛЯЕТ HASH ПРОМИС!!! НУЖНО ИСПРАВИТЬ

  watch: {
    files: {
      handler(val) {
        for (const [key, item] of Object.entries(this.files)) {
          if (item.hash === "") {
            let callback = function (total, now) {
              item.progress = Math.round((now / total) * 100)
            }
            item.callback = callback;
            item.hash = "calc..."
            let process = this.$store.dispatch("files/calc_file_hash", item).then(result => {
              item.hash = result.hash;
            })
          }
          if (!["", "calc..."].includes(item.hash)) {
            this.value[key] = item
          }
        }
        this.$emit("input", this.files)
      },
      deep: true
    }
  },
  methods: {
    remove_file(index) {
      console.log('file', index)
      console.log("start remove", this.files[index].calc_hash, this.files[index])
      this.files[index].calc_hash = false
      this.files.splice(index, 1)
      console.log(this.files)
    },
    add_file() {
      let input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;

      input.onchange = () => {
        const files_to_add = Array.from(input.files);
        for (const [key, file] of Object.entries(files_to_add)) {

          this.files.push({
            file_obj: file,
            hash: "",
            progress: 0,
            calc_hash: true,
          })

        }

      };

      input.click();

    },
    file_size_str(file_size) {
      let str_size = ""
      if (file_size > 1073741824) {
        str_size = `${(file_size / 1073741824).toFixed(2)} GB`
      } else if (file_size > 1048576) {
        str_size = `${(file_size / 1048576).toFixed(2)} MB`
      } else {
        str_size = `${(file_size / 1024).toFixed(2)} KB`
      }
      return str_size
    }
  }
}
</script>

<style scoped>

</style>