<template>
  <section>
    <v-app>
      <v-row>
        <v-col></v-col>
        <v-col style="padding-top : 20%">
          <div class="text-h4">
            Установите пароль
          </div>
          {{ this.message }}
          <v-form
              ref="form"
              v-on:submit="onSubmit"
          >
            <v-text-field
                v-model="password"
                label="Введите пароль"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                required
            ></v-text-field>
            <v-text-field
                v-model="retry_password"
                label="Повторите введённый пароль"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                counter
                @click:append="show = !show"
            ></v-text-field>

            <v-btn
                type="submit"
            >
              Сохранить
            </v-btn>
          </v-form>
        </v-col>
        <v-col></v-col>
      </v-row>
      <GlobalSnackBar/>
    </v-app>
  </section>

</template>

<script>


import NavBar from "../components/NavBar";
import GlobalSnackBar from "../components/GlobalSnackBar";
export default {
  name: "SetPassword",
  components: {GlobalSnackBar, NavBar},
  props: {
    code: String,
  },
  data() {
    return {
      show: false,
      password: "",
      retry_password: "",
      message: "",
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      if (this.password === this.retry_password) {
        this.$store.dispatch("current_user/activate_user", {
          code: this.code,
          password: this.password.trim()
        }).then(result => {
          if (result["status_code"] === "3000") {
            this.$store.commit("snackbar/add_snackbar", {text: "Пароль устоновлен", type: "success", timeout: 3000})
            this.$router.push({path: "/login"})
          }
        })
      } else {
        this.$store.commit("snackbar/add_snackbar", {
          text: "Пароль неверный. Проверьте написание",
          type: "error",
          timeout: 3000
        })
      }
    },
  }
}
</script>

<style scoped>
</style>