import Vue from 'vue'
import Vuex from 'vuex'

import current_user from "./modules/current_user"
import categories_partitions from "./modules/categories_partitions";
import search_params from "./modules/search_params"
import tags from "./modules/tags";
import notes from "./modules/notes";
import files from "./modules/files";
import users from "./modules/users"
import viewFile from "./modules/file_edit/viewFile";
import snackbar from "./modules/snackbar"

import RequestApi from "../func/RequestApi";
import global_const from '../global_const'
import router from "../router"
import interface_app from "./modules/interface_app";

Vue.use(Vuex)
//Мама я конфигкодер :>

export default new Vuex.Store({
    state: {
        api: new RequestApi(global_const.api_url, undefined, undefined)
    },
    getters: {},
    mutations: {
        update_api(state) {
            state.api = new RequestApi(global_const.api_url,
                state.current_user.access_token, state.current_user.refresh_token);
        }
    },
    actions: {
        async initialize_all_date({dispatch, state}, payload) {
            return dispatch("categories_partitions/get_all")

        },
        async update_api({state, commit, dispatch}, payload) {
            return new Promise((resolve) => {
                commit("update_api");
                resolve()
            })
        }

    },
    modules: {
        viewFile,
        notes, files,
        current_user, tags,
        categories_partitions,
        search_params,
        users, snackbar, interface_app
    }
})
