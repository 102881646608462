<template>
  <v-system-bar
      v-bind:style="{position : 'relative'}"
      height="34"
      app
      elevation="1"
      class="button-group d-flex justify-center align-content-center"
      color="#E0E0E0"
  >
    <v-btn v-on:click="goto_to_page('/search_notes')" text>Поиск заметок</v-btn>

    <v-btn v-on:click="goto_to_page('/add_note')" text>Добавить заметку</v-btn>

    <v-btn :hidden="show_editors" v-on:click="goto_to_page('/other')" text> Категории\Разделы\Теги</v-btn>
    <v-btn :hidden="show_admins" v-on:click="goto_to_page('/users_page')" text>Пользователи</v-btn>
    <v-spacer></v-spacer>

    <v-btn color="deep-red" v-on:click="logout" icon>
      <v-icon color="red">
        mdi-logout
      </v-icon>
    </v-btn>
    <GlobalSnackBar/>
  </v-system-bar>

</template>

<script>
import Dropdown from "./Dropdown.vue";
import GlobalSnackBar from "./GlobalSnackBar.vue"

export default {
  name: "NavBar",

  components: {
    Dropdown, GlobalSnackBar
  },
  computed: {
    show_editors() {
      return !["admin", "editor"].includes(this.$store.getters["current_user/current_user_level_access"]);
    },
    show_admins() {
      return !["admin"].includes(this.$store.getters["current_user/current_user_level_access"]);
    }
  },
  methods: {
    goto_to_page(path_val) {
      this.$router.push({path: path_val})
    },
    logout() {
      this.$store.dispatch("current_user/logout")
    }
  },

  data() {
    return {
      categories: [
        {title: "Рыба"},
        {title: "Рыба"},
        {title: "Рыба"},
        {title: "Рыба"},
        {title: "Рыба"},
        {title: "Рыба"},
        {title: "Рыба"},
      ],
    };
  },
};
</script>

<style scoped>
.button-group {
  gap: 1rem;
}
</style>