<template>
  <v-container>
    <v-text-field v-model="user_info.full_name" label="Полное имя пользователя">
    </v-text-field>

    <v-text-field v-model="user_info.email"
                  :rules="[rules.required, rules.email]"
                  label="E-mail">
    </v-text-field>
    <v-select
        readonly
        v-model="user_info['level_access']"
        :items="list_permission_user"
        label="Уровень доступа"
    ></v-select>
    <v-select
        v-model="user_info.list_category_id"
        :items="$store.getters['categories_partitions/get_all_categories']"
        chips
        label="Разрешенное редактирование категорий"
        multiple
        v-on:change="change_categories"
    ></v-select>
    <p>Статус пользователя: {{ (user_info.activated === 1) ? "Активен" : "Отключен" }}</p>
    <v-textarea v-model="user_info.additional_information" label="Доп.информация">
    </v-textarea>
    <div>
      <v-btn @click="update_user" class="mx-2">Сохранить изменения</v-btn>
      <v-btn @click="deactivate_user" class="mx-2">Отключить пользователя</v-btn>
      <v-btn @click="reset_password" class="mx-2">Сбросить пароль</v-btn>
      <v-dialog
          v-model="hang_over_all_files"
          width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="lighten-2"
              v-bind="attrs"
              v-on="on"
          >
            Передать все файлы другому пользователю
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Выберете пользователя
          </v-card-title>

          <v-card-text>
            <v-autocomplete
                :items="username_cache_list"
                :search-input.sync="search"
                color="blue"
                v-model="new_user_to_all_files"
                :no-data-text="'Нету такого пользователя '"
                hide-selected
                label="Имя"
                placeholder="Ведите имя пользователя"
            ></v-autocomplete>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="hang_over_all_files = false"
            >
              Отмена
            </v-btn>
            <v-btn
                color="red"
                text
                :disabled="new_user_to_all_files === 0"
                @click="hang_all_files"
            >
              Передать все файлы
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn @click="$emit('update:userId', 0)" class="mx-2">Закрыть</v-btn>
    </div>
    <v-snackbar
        v-model="show_snackbar"
        :timeout="3000"
    >
      {{ message_str }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="show_snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

</template>

<script>
const rusLower = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя'
const rusUpper = rusLower.toUpperCase()
export default {
  props: {
    userId: Number
  },
  computed: {
    show_snackbar: {
      get() {
        return this.message_str.length > 0
      },
      set(value) {
        if (!value) {
          this.message_str = ""
        }
      }

    }
  },
  created() {
    this.update_local_data()
  },
  methods: {
    change_categories(value) {
      this.user_info.list_category_id = value.filter(value => value !== 0)
      if (this.user_info.list_category_id.length > 0) {
        this.user_info.level_access = "editor"
      } else {
        this.user_info.level_access = "user"
      }
    },
    update_local_data() {
      this.$store.dispatch("users/get_user_info", this.userId).then(result => {
        if (result["status_code"] === "3000") {
          this.user_info = result["result"]
          this.old_value = Object.assign({}, result["result"]);
        }
      })

    },
    update_user() {
      let new_value = this.user_info
      let old_value = this.old_value
      //console.log(new_value.list_category_id, old_value.list_category_id)
      let payload = {
        user_id: this.userId,
        full_name: (new_value.full_name !== old_value.full_name) ? new_value.full_name : undefined,
        email: (new_value.email !== old_value.email) ? new_value.email : undefined,
        level_access: (new_value.level_access !== old_value.level_access) ? new_value.level_access : undefined,
        additional_information: (new_value.additional_information !== old_value.additional_information) ? new_value.additional_information : undefined,
        list_category_id: (new_value.list_category_id !== old_value.list_category_id) ? new_value.list_category_id : undefined
      }
      this.$store.dispatch("users/update_user", payload).then(result => {
        if (result["result"] === true) {
          this.$store.commit("snackbar/add_snackbar", {text: "Изменения сохранены", type: "success", timeout: 3000})
          this.update_local_data()
        } else {
          this.$store.commit("snackbar/add_snackbar", {text: "Произошла ошибка", type: "error", timeout: 3000})
        }
      })

    },
    reset_password() {
      let payload = {
        user_id: this.userId,
        email: this.user_info.email,
      }
      this.$store.dispatch("users/update_user", payload).then(result => {
        if (result["result"] === true) {
          this.$store.commit("snackbar/add_snackbar", {text: "Пароль сброшен", type: "success", timeout: 3000})
          //this.message_str = "Пароль сброшен"
          this.update_local_data()
        } else {
          this.$store.commit("snackbar/add_snackbar", {text: "Произошла ошибка", type: "error", timeout: 3000})
        }
      })
    },
    deactivate_user() {
      this.$store.dispatch("users/deactivate_user", {user_id: this.userId}).then(result => {
        if (result["result"] === true) {
          this.$store.commit("snackbar/add_snackbar", {text: "Пароль сброшен", type: "success", timeout: 3000})
          this.update_local_data()
        } else {
          this.$store.commit("snackbar/add_snackbar", {text: "Произошла ошибка", type: "error", timeout: 3000})
        }
      })
    },
    hang_all_files() {

      this.$store.dispatch("users/hand_over_all_notes", {
        old_user: this.userId,
        new_user: this.new_user_to_all_files
      }).then(
          result => {
            if (result["result"] === true) {
              this.hang_over_all_files = false
              this.new_user_to_all_files = 0
              this.$store.commit("snackbar/add_snackbar", {text: "Все файлы переданы", type: "success", timeout: 3000})
              this.update_local_data()
            } else {
              this.$store.commit("snackbar/add_snackbar", {text: "Произошла ошибка", type: "error", timeout: 3000})
            }
          }
      )
    }
  },
  watch: {
    userId() {
      //console.log("value change", this.userId)
      if (this.userId === 0) {
        this.user_info = {
          email: "",
          full_name: "",
          additional_information: "",
          level_access: "user",
          list_category_id: []
        }
        this.old_value = {}
        return;
      }
      this.update_local_data()
    },
    search(search_str) {
      if (search_str === null) {
        return
      }
      let temp_username = []
      let payload = {
        search_line: search_str
      }
      if (rusLower.includes(search_str[0]) || rusUpper.includes(search_str[0])) {
        payload["type_search"] = "name";
      } else {
        payload["type_search"] = "email";
      }

      this.$store.dispatch("users/find_user", payload).then(result => {
        for (const [key, value] of Object.entries(result)) {
          //console.log(key)
          temp_username.push({
            text: `${value.full_name} (${value.email})`,
            value: Number(key),
          })
        }
        this.username_cache_list = temp_username
      })

    }
  },
  data: () => ({
    search: "",
    username_cache_list: [],
    new_user_to_all_files: 0,
    hang_over_all_files: false,
    message_str: "",
    old_value: {},
    user_info: {
      email: "",
      full_name: "",
      additional_information: "",
      level_access: "user",
      list_category_id: []
    },
    list_permission_user: [
      {text: "Пользователь", value: "user"},
      {text: "Редактор", value: "editor"},
      {text: "Администратор", value: "admin"}
    ],
    rules: {
      required: value => !!value || 'Обязательное поле.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Неверный e-mail.'
      },
    },
  }),
  name: "EditUser"
}
</script>


<style scoped>

</style>