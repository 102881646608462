<template>
  <div class="app">
    <v-container fluid="md" class="bg-light mt-5 h-100">
      <v-row>

        <v-col border="right" md="4" col="6" align-self="stretch" class="border-right">
          <v-select
              v-on:change="change_categories"
              :items='$store.getters["categories_partitions/get_all_categories"]'
              label="Выберете категорию"
              solo
          ></v-select>
          <v-select
              v-on:change="change_partitions"
              :items='$store.getters["categories_partitions/get_partitions"](now_partitions)'
              :disabled='$store.getters["categories_partitions/get_partitions"](now_partitions).length ===0'
              label="Выберете раздел"
              solo
          ></v-select>

        </v-col>

        <v-col align-self="stretch">

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FindUserForm from "../components/FindUserForm.vue";

export default {
  name: 'SearchFiles',
  components: {
    FindUserForm
  },

  methods: {
    change_categories(value) {
      this.now_partitions = value
      //console.log(value)
    },
    change_partitions(value) {

    }
  },
  data() {
    return {
      now_partitions: 0
    }

  }
}
</script>

<style scoped>
.app {
  height: 40rem;
}

</style>
