<template>
  <div class="FindForm w-50">
       <b-form inline>
        <label class="sr-only" for="inline-form-input-email">Email</label>
        <b-form-input
          id="inline-form-input-email"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="username@example.com"
        ></b-form-input>

        <label class="sr-only" for="inline-form-input-username">Username</label>
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input id="inline-form-input-username" placeholder="Username"></b-form-input>
        </b-input-group>

        <b-button variant="outline-primary" class="mt-2 float-right">Save</b-button>
      </b-form>
  </div>
</template>

<script>
export default {
  name: 'FindUserForm',
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>