<template>
  <div>
    <v-dialog
        v-model="value"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Вы уверены что хотите удалить файл?</span>
        </v-card-title>
        <v-card-text>
          В случае удалении восстановить его можно только при помощи администратора
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="$emit('input', false)"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              v-on:click="remove_file"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "RemoveFIle",
  props: {
    value: Boolean,
    note_id: Number
  },
  methods: {
    remove_file() {
      this.$store.dispatch("notes/remove", {note_uuid: this.note_id}).then(result => {
        if (result["status_code"] === "3000") {
          this.$router.push({path: "/search_notes"})
        }
      })

    }
  }
}
</script>

<style scoped>

</style>