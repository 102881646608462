<template>
  <div class="text-center">
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          {{ title }}
        </v-btn>
      </template>

      <div class="pa-2 my-2 fold d-flex col-xl-6">
        <slot> </slot>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    title: String
  },
};
</script>

<style scoped>
  .fold {
    background-color: #FAFAFA;
  }
</style>