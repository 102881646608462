import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import VueMarkdown from 'vue-markdown'
// Vue.use(DropdownPlugin)
// Vue.use(TablePlugin)
// Vue.use(CardPlugin)
// Vue.use(IconsPlugin)
// Vue.use(VBScrollspyPlugin)
// Vue.use(LayoutPlugin)
// Vue.use(BootstrapVue)
// Vue.use(ModalPlugin)

Vue.config.productionTip = false

new Vue({
    components: {
        VueMarkdown
    },
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
