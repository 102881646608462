import xxhash from "xxhash-wasm";
import {FileReadPart, ReadAsArrayBuffer} from "../../func/utils_func"
import global_const from "../../global_const";

export default {
    namespaced: true,
    state: {
        files_on_page: {}
    },
    getters: {
        list_notes(state, getters, rootState, rootGetters) {
            let list_files = [];

            for (const [index, value] of Object.entries(state.files_on_page)) {
                list_files.push({note_uuid: index, ...value})
            }

            list_files.sort(function (a, b) {
                const a_time = new Date(a.created_at.time).getTime()
                const b_time = new Date(b.created_at.time).getTime()
                return a_time - b_time;
            })
            if (rootGetters["search_params/type_search"] !== "old") {
                list_files.reverse()
            }
            return list_files
        }
    },

    mutations: {
        set_notes_on_page(state, payload) {
            state.files_on_page = payload

        },
        add_new_file_to_cache_storage(state, payload) {
            //Если файл был открыт по сыллке то мы фигачем его в хранилище
        }
    },

    actions: {
        async calc_file_hash({rootState}, payload) {
            //TODO place to worker?
            const buffer_length = 1024 * 1024 * 128
            const {create64} = await xxhash();

            const FileToRead = payload.file_obj

            const count = Math.round(FileToRead.size / buffer_length) + 1
            const now_harsher = create64()

            let reader = new FileReader()
            let start = 0
            let part_file = undefined
            let list_promise = []
            payload.callback(100, 0)

            for (let i = 0; i < count; i++) {
                start = i * buffer_length
                list_promise.push(FileReadPart(reader, FileToRead, start, buffer_length, payload.callback))
            }

            // if (payload.callback) {
            //     payload.callback(count, 0)
            // }
            for (let i in list_promise) {
                part_file = await list_promise[i]()
                now_harsher.update(new Uint8Array(part_file))
                // if (payload.callback) {
                //     payload.callback(count, i)
                // }
            }
            if (payload.callback) {
                payload.callback(count, count)
            }
            const hash_file = now_harsher.digest().toString(16)
            //console.log(hash_file)
            let result = await rootState.api.get(`/file/find_by_hash/${hash_file}`)
            if (result["result"]["files"]) {
                return {hash_file: hash_file, unique: false}
            } else {
                return {hash_file: hash_file, unique: true}
            }
        },
        async add_note({rootState}, payload) {
            return await rootState.api.uploadFormData("/note/add", payload.note, payload.files, payload.callback)
        },
        async change_file_owner({rootState}, payload) {
            return rootState.api.uploadFormData("/note/update", payload, [], function (vla1, val2) {
            })
        },
        async edit_note({rootState}, payload) {
            return await rootState.api.uploadFormData("/note/update", payload.note, payload.files, payload.callback)
        },
        async get_info({commit, state, rootState}, payload) {
            const response = await rootState.api.get("/note/get/" + payload)
            return response["result"]
        },
        download_file({rootState}, payload) {
            rootState.api.get("/file/get/token/" + payload).then(result => {
                window.open(`${global_const.api_url}/file/get/bytes/${result["token"]}`, "_blank");
            })
        },
        async remove({rootState}, payload) {
            return rootState.api.delete("/note/remove", payload)
        }
    },
}
